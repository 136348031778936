import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
    "@media (max-width:550px)":{
        popularVenueHead:{
            padding: "18px 18px 10px 16px"
        },
        popularVenueText:{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "16px",
            textTransform: "capitalize",
            color: "#212121"
        },
        venueImg: {
            width: "42px",
            height: "42px",
          },
        hr:{
            margin: 0,
            color: "#98A0A2",
            border: "none",
            borderTop: "1px solid #EDF3FD",
         },
        listVenueCard:{
            background: "#FFFFFF",
            margin: "8px 0px",
        },
        mRight:{
            marginRight:"25px"
        },
        joinNow:{
            fontWeight: 600,
            textDecoration: "none",
            fontSize: "14px",
            lineHeight: "100%",
            textAlign: "right",
            color: "#007E33",
        },
        partnerContainer:{
            overflowY: "hidden",
            padding: "22px 0px 18px 16px",
            msOverflowStyle:"none",
            scrollbarWidth: "none",

            "&::-webkit-scrollbar": {
                display: "none",
            },
            "&::-moz-scrollbars": {
                display: "none",
              },
         },
          partnerCardImg:{
            margin: "0 auto",
            display: "inherit",
          },
          partnerCardText:{
            fontWeight: 600,
            fontSize: "10px",
            lineHeight: "13px",
            textAlign: "center",
            color: "#172B4D"
          },
          flex:{
            display:"flex"
            }, 
            spaceBw:{
            justifyContent:"space-between"
            },
            alignCenter:{
                alignItems: "center"
            },
            flexCol:{
            flexDirection:"column"
            },
            justifyCenter:{
                justifyContent: "center"
            },
            relative:{
                position: "relative"
            },
            absolute:{
                position: "absolute"
            },
            alignEnd:{
                alignItems: "end"
            },
            flex25:{
                flex: "0 1 25%"
            }
      }
}));

export default style;