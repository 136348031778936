import { React, useState, useRef } from "react";
import clsx from "clsx";
import SectionWithScrollButtons from "../Common/SectionWithScrollButtons";
import useStyles from "./styles";
import BannerLoader from "../skeletonLoader/BannerLoader";

export default function DeskReview({ cardData, loading }) {
  const classes = useStyles();
  const scrl = useRef(null);

  return (
    <>
    <div> 
      <div className={classes.kmGallery}>
        Customer Reviews
      </div>
      {/* <SectionWithScrollButtons
        isBg={true}
        onClickRight={() => {
          scrl.current.scrollLeft += 250;
        }}
        onClickLeft={() => {
          scrl.current.scrollLeft -= 250;
        }}
      > */}
        <div
          className={clsx(classes.flex, classes.scrollContainer)}
          // id="content"
          ref={scrl}
        >
      {loading ? <BannerLoader /> :
          cardData?.map((details, idx) => (
            <div key={idx}  className={classes.homeBanner}>
              <div className={classes.cusName}>{details?.customerName}</div>
              <img className={classes.ratingImg} src={details?.ratingImageURL} alt="rating image" />
              <div className={classes.reviewText}>{details?.customerReview}</div>
              <div className={clsx(classes.flex,classes.alignCenter,classes.mt30)}>
                  <img className={classes.reviewTag} src={details?.tagImageURL} alt="rating tag" />
                  <div className={classes.userType}>
                    {details?.userType}
                  </div>
              </div>
            </div>
          ))
        } 
        </div>
      {/* </SectionWithScrollButtons> */}
    </div>
    </>
  );
}
