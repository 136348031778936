export const reviewData = [
  {
    customerName:"Chirag Chedda",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    customerReview:"Have been using this app to book cricket turfs and it's the best I have come across. The turf options are great and their customer support team is also very helpful and quick to revert. I regularly get offers and coupons also which gives a good discount for my turf booking.",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Pay N Play User",
  },
  {
    customerName:"Jyothslina Paul",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    customerReview:"I’ve been using KheloMore for football training for my kid at the comfort of my residence. The coaches of KheloMore are really knowledgeable and treat kids with care and caution. Recommend 10/10.",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Coaching User",
  },
  {
    customerName:"Harsh Agarwal",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    customerReview:"Woke up with a hangover on a Saturday morning to a message on Bumble insisting on an offbeat date. So I booked a badminton session on KheloMore. The app is sleek and fast, has plenty of options, time slots, and offers. Was a great first date.",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Pay N Play User",
  },
  {
    customerName:"Anuradha Singh",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    customerReview:"It's a very good app, I first booked a venue to play badminton from khelomore and then we use to book regularly. Booking is very easy and customer support is significant. Happy to share that my nephew is learning football in his society from Khelomore team as well.",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Pay N Play User",
  },
  {
    customerName:"Shubham Bathe",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    customerReview:"Khelomore is the best app for booking various sports facilities nearby your area. The app is user-friendly, easy to navigate with clean and well defined UI/UX. Also there are various discounts available in app from time to time. Must try... ⚽💪",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Pay N Play User",
  },
  {
    customerName:"Jerry Jose",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    customerReview:"Good experience with the khelomore. The best thing about this app is it provides the best discount in the market. Playo is little costly when comparing to khelomore. It is a money saver plus it is the best alternative for playo. Highly recommended",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Pay N Play User",
  },
];