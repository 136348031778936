import { alpha } from "@material-ui/core/styles/colorManipulator";
import theme from "./theme";

const commonStyles = {
  /* css for common fonts !! do not edit !! */
  headline1: {
    fontSize: 24,
    fontWeight: "bold",
  },
  headline2: {
    fontSize: 20,
    fontWeight: "bold",
  },
  headline3: {
    fontSize: 16,
    fontWeight: "bold",
  },
  headline4: {
    fontSize: 14,
    fontWeight: "bold",
  },
  headline4: {
    fontSize: 12,
    fontWeight: "bold",
  },
  headline6: {
    fontSize: 10,
    fontWeight: "bold",
  },
  headline7: {
    fontSize: 8,
    fontWeight: "bold",
  },

  title1: {
    fontSize: 24,
    fontWeight: 600,
  },
  title2: {
    fontSize: 20,
    fontWeight: 600,
  },
  title3: {
    fontSize: 16,
    fontWeight: 600,
  },
  title4: {
    fontSize: 14,
    fontWeight: 600,
  },
  title5: {
    fontSize: 12,
    fontWeight: 600,
  },
  title6: {
    fontSize: 10,
    fontWeight: 600,
  },
  title7: {
    fontSize: 8,
    fontWeight: 600,
  },
  title3_new: {
    fontSize: 16,
    fontWeight: 700,
  },
  title4_new: {
    fontSize: 14,
    fontWeight: 700,
  },
  title5_new: {
    fontSize: 12,
    fontWeight: 700,
  },

  subtitle1: {
    fontSize: 24,
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: 20,
    fontWeight: 500,
  },
  subtitle3: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitle4: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle5: {
    fontSize: 12,
    fontWeight: 500,
  },
  subtitle6: {
    fontSize: 10,
    fontWeight: 500,
  },
  subtitle7: {
    fontSize: 8,
    fontWeight: 500,
  },


  // css for common buttons
  siteButton: {
    borderRadius: 4,
    fontSize: 15,
    fontWeight: 700,
    lineHeight: "15px",
    letterSpacing: 1.25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    textTransform: "uppercase",
    textDecoration: "none",
    outline: "none",
    padding: "0 16px",
    cursor: "pointer",
  },
  secondary: {
    background: theme.palette.whiteColor,
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: alpha(theme.palette.secondary.main, 0.2),
    },
    "&:active": {
      background: alpha(theme.palette.secondary.main, 0.2),
    },
  },
  success: {
    background: theme.status.success,
    color: theme.palette.whiteColor,
    "&:hover": {
      background: alpha(theme.status.success, 0.9),
    },
    "&:active": {
      background: alpha(theme.status.success, 0.9),
    },
  },

  userNameSpans: {
    fontSize: "12px",
    fontWeight: 500,
  },

  emptyMessage: {
    padding: "48px 16px 16px 16px",
    textAlign: "center",

    "& h4": {
      fontSize: "15px",
      fontWeight: 600,
      marginBottom: 8,
    },
    "& p": {
      fontSize: "12px",
      fontWeight: 500,
    },
  },
};

export default commonStyles;
