import { React, useState } from "react";
import clsx from "clsx";
import useStyles from "./styles";
import BannerLoader from "../skeletonLoader/BannerLoader";

export default function MobilePtCards({ mobCardData, loading }) {
  const classes = useStyles();
  return (
    <>
      <div>
        <div
          className={clsx(
            classes.flex,
            classes.spaceBw,
            classes.popularVenueHead
          )}
        >
          <div className={clsx(classes.flex, classes.alignCenter)}>
            <h2 className={clsx(classes.popularVenueText)}>
            Cricket Special By Khelomore
            </h2>
          </div>
          {/* <div
            className={clsx(
              classes.viewAllBtn,
              classes.flex,
              classes.alignCenter,
              classes.justifyCenter
            )}
          >
            <a
              className={clsx(
                classes.viewAll,
                classes.flex,
                classes.alignCenter
              )}
              href="https://www.khelomore.com/sports-coaching/one-on-one-personal-training"
            >
              View all
            </a>
          </div> */}
        </div>

        <div
          className={clsx(classes.flex, classes.scrollContainer)}
        >
          {loading ? (
            <BannerLoader />
          ) : (
            mobCardData?.map((details, idx) => (
              <a
                key={idx}
                href={details.redirectionURL}
                className={classes.homeBanner}
                target="_blank"
              >
                <img src={details.imageURL} alt="pt card" />
                <div className={classes.cardName}>
                  {details?.firstName} <br />
                  {details?.secondName}
                </div>
              </a>
            ))
          )}
        </div>
      </div>
    </>
  );
}
