import React from "react";
import { isMobile } from "react-device-detect";
import { useQuery } from "@apollo/client";
import DesktopHeader from "../../Header/DesktopHeader";
import MobileHeader from "../../Header/MobileHeader";
import DeskFooter from "../DeskFooter";
import MobileFooter from "../MobileFooter";

import { PARTNERSHIP } from "../../../gqlOperations/queries";

export default function Partnerships(){
  document.title = "Partnerships | KheloMore";

  const {loading, error , data} = useQuery(PARTNERSHIP);

  const htmlData = data?.partnerships?.data.map((details) => details?.attributes?.Value);
  
  return(
    <>
    {isMobile ? 
    <div>
      <MobileHeader />
        <div>
          <div dangerouslySetInnerHTML={{__html: htmlData}}/>
        </div>
      <MobileFooter />
    </div>
    :
    <div>
      <DesktopHeader/>
        <div>
          <div dangerouslySetInnerHTML={{__html: htmlData}}/>
        </div>
      <DeskFooter />    
    </div>
    }
        
    </>
  )
}
