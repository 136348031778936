import {makeStyles} from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
    "@media (max-width: 550px)":{
        appCard:{
            backgroundColor: "#000000",
            padding: "8px 16px 8px 14px",
            alignItems: "center",
            position:"fixed",
            left:"0",
            right:"0",
            bottom:"56px",
            zIndex:99,
            maxWidth:"100%"

        },
        crossIcon:{
            height: "12px",
            width: "12px",
            alignItems: "center",
            marginRight: "11px"
        },
        appIcon:{
            padding:"6px",
            background: "#FFFFFF",
            borderRadius: "4px",
            margin: "0px 12px",
        },
        appText:{
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#FFFFFF"
        },
        kmIcon:{
            width: "18px",
            height: "18px"
        },
        straightLine:{
            width: "1px",
            height: "44px",
            backgroundColor: "#FFFFFF",
        },
        openApp:{
            border: "none",
            width: "100px",
            height: "36px",
            background: "#FFFFFF",
            borderRadius: "18px",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.933333px",
            textTransform: "uppercase",
            color: "#FF4E00",
            marginLeft:"10px",
        },
          flex:{
            display:"flex"
          }, 
          spaceBw:{
            justifyContent:"space-between"
          },
          alignCenter:{
            alignItems: "center"
          },
          flexCol:{
            flexDirection:"column"
          },
          justifyCenter:{
              justifyContent: "center"
          }
       }
}))

export default style; 
