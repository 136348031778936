import { React, useState } from "react";
import clsx from "clsx";
import useStyles from "./styles"
import kmIcon from "../../icons/km_Icon.svg";
import crossIcon from "../../icons/cross_Icon.svg"

export default function OpenAppCard() {
	const classes = useStyles();
	const [showDescription, setShowDescription] = useState(true);

	const toggleDescription = () => setShowDescription(!showDescription)

	return (
		<>
			{
				showDescription ? <div className={clsx(classes.flex, classes.appCard, classes.spaceBw)}>
					<div className={clsx(classes.flex, classes.alignCenter)}>
						<img onClick={toggleDescription} className={classes.crossIcon} src={crossIcon} alt="icon" />
						<div className={classes.straightLine}>
						</div>
						<div className={clsx(classes.appIcon, classes.flex, classes.alignCenter, classes.justifyCenter)}>
							<img className={classes.kmIcon} src={kmIcon} alt="km_icon" />
						</div>
						<div>
							<p className={classes.appText}>The easiest way to book a venue and play your sport!</p>
						</div>
					</div>
					<div>
						<a href="https://tna61.app.link/ruFkgipnAsb">
							<button className={classes.openApp}>Open APP</button>
						</a>
					</div>
				</div>
					:
					null
			}
		</>
	)
}