import React from "react";
import clsx from "clsx";
import useStyles from "./styles"
import right_Arrow from "../../icons/right_Arrow.svg";


export default function MobileTrainingVenue({activeMode, modes, handleTrainingData}){
	const classes = useStyles();

	return(
		<>
			<div className={clsx(classes.flex, classes.spaceBw, classes.popularVenueHead)}>
				<h2 className={clsx(classes.popularVenueText)}>Available Societies</h2>
				<a href="https://khelomore.com/sports-coaching">
					<span className={classes.viewAll}>View All</span>
				</a>
			</div>
			<hr className={clsx(classes.hr)}></hr>
			<div className={clsx(classes.locationTag)}>
			{modes?.map((mode,idx) => (
				<button key={idx} onClick={() => handleTrainingData(mode)} className={clsx(mode === activeMode ? classes.locationButtonActive : classes.locationButton)}>{mode}</button>
			))}
			</div>
		</>
	)
}