import { makeStyles } from "@material-ui/core";

const style = makeStyles((theme) => ({
  "@media (max-width: 550px)": {
    navBar: {
      background: "#FFFFFF",
      height: "56px",
      boxShadow: "0px 4px 8px #212121",
      padding: "18px 28px",
      position: "fixed",
      left: "0",
      right: "0",
      bottom: "0px",
      zIndex: "99",
    },
    homeIcon: {
      width: "20px",
      height: "20px",
    },
    icon: {
      width: "20px",
      height: "20px",
    },
    activeNavText: {
      fontWeight: 700,
      fontSize: "10px",
      lineHeight: "100%",
      letterSpacing: "0.4px",
      textTransform: "uppercase",
      color: "#FF4E00",
    },
    navText: {
      fontWeight: 700,
      fontSize: "10px",
      lineHeight: "100%",
      letterSpacing: "0.4px",
      textTransform: "uppercase",
      color: "#000000",
    },
    flex: {
      display: "flex",
    },
    spaceBw: {
      justifyContent: "space-between",
    },
    alignCenter: {
      alignItems: "center",
    },
    flexCol: {
      flexDirection: "column",
    },
    justifyCenter: {
      justifyContent: "center",
    },
  },
}));

export default style;
