import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../theme/common.styles";

const style = makeStyles((theme) => ({
  fav: {
    background: "#FFFFFF",
    width: "50%",
    borderRadius: "8px",
    padding: "24px 24px 19px 24px",
    marginRight:"20px",
  },
  viewAllBtn: {
    height: "40px",
    borderRadius: "12px",
    padding: "8px",
  },
  joinUsBtn: {
    height: "40px",
    borderRadius: "12px",
    padding: "8px",
    background: "rgba(71, 169, 255, 0.1)",
  },
  viewAll: {
    ...commonStyles.title3,
    lineHeight: "20px",
    color: "#47A9FF",
  },
  mRight:{
    marginRight:"20px"
  },
  mTop:{
    marginTop:"6px"
  },
  cardWidth:{
    width:"120px",
  },
  rightArrow: {
    marginLeft: "8px",
  },
  imageCard: {
    width: "72px",
    height: "72px",
  },
  commonMargin: {
    margin: "15px 20px",
  },
  paddingBtm: {
    paddingBottom: "10px",
  },
  favHead: {
    fontSize:24,
    fontWeight:600,
    lineHeight: "30px",
    textTransform: "capitalize",
    color: "#212121",
  },
  favCardTop: {
    marginBottom: "12px",
  },
  hr: {
    margin: 0,
    color: "#98A0A2",
    border: "none",
    borderTop: "1px solid #EDF3FD",
  },

  cardTextHead: {
    ...commonStyles.title3,
    lineHeight: "20px",
    color: "#172B4D",
    textAlign: "center",
    textTransform: "capitalize",
    marginTop: "8px",
    width:"120px"
  },
  cardTextPara: {
    ...commonStyles.title4,
    lineHeight: "19px",
    color: "#BDBDBD",
  },
  scrollContainer: {
    overflowY: "hidden",
    // whiteSpace: "nowrap",
    // padding: "18px 0px 16px 12px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  arrowIcons: {
    zIndex: 99999,
    position: "relative",
  },
  leftArrow: {
    width: "14px",
    position: "absolute",
    top: "65px",
    bottom: "0",
    left: "-10px",
    right: 0,
    backgroundColor: "rgba(0,0,0,0.2)",
    padding: "3px",
    borderRadius: "50%",
  },
  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  flexCol: {
    flexDirection: "column",
  },
  alignCenter: {
    alignItems: "center",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  flex18: {
    flex: "0 1 18%",
  },
  popularVenueHead: {
    padding: "12px 18px 10px 16px",
  },
  popularVenueText: {
    ...commonStyles.title3,
    lineHeight: "16px",
    textTransform: "capitalize",
    color: "#212121",
  },
  hr: {
    margin: 0,
    color: "#98A0A2",
    border: "none",
    borderTop: "1px solid #EDF3FD",
  },
  joinNow: {
    ...commonStyles.title4,
    textDecoration: "none",
    lineHeight: "100%",
    textAlign: "right",
    color: "#007E33",
  },
  partnerCardText: {
    margin: "0 auto",
    padding: "8px",
    ...commonStyles.title6,
    lineHeight: "13px",
    textAlign: "center",
    color: "#172B4D",
  },
}));

export default style;
