import React, { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useStyles from "./styles";
import RightDrawer from "../Common/RightDrawer";
import LoginHamburger from "../RightHamburger/LoginHamburger";
import KM_Icon from "../../icons/KM_LOGO.svg";
import HB_Icon from "../../icons/HB_Icon.svg";
// import apple_store from "../../icons/apple_store.svg";
// import g_playStore from "../../icons/g_play.svg"



export default function MobileHeader() {
	const classes = useStyles();

	const [state, setState] = useState({
		sidePanelOpen: false,
	});

	const rightDrawerOpen = () => {
		setState((prevPanelState) => {
			return {
				sidePanelOpen: !prevPanelState.sidePanelOpen,
			};
		});
	};

	const rightDrawerClose = () => {
		setState({ sidePanelOpen: false });
	};

	return (
		<>
			<header className={clsx(classes.header)}>
				<Link to="/">
					<img className={classes.kmLogo} src={KM_Icon} alt="KM-logo" />
				</Link>
				<img className={classes.hbIcon} src={HB_Icon} alt="KM-logo" onClick={rightDrawerOpen} />
			</header>

			<RightDrawer
				isSidePanelOpen={state.sidePanelOpen}
				closePanel={rightDrawerClose}
			>
				<div className={classes.hamburgerContainer}>
					<LoginHamburger rightDrawerClose={rightDrawerClose} />
				</div>
			</RightDrawer>
		</>
	)
}