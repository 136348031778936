import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import RegisterBanner from "../../../images/hamburger-Banner.png";

import userIcon from "../../../icons/user-icon.svg";
import turfIcon from "../../../icons/newPnp.png";
import eventIcon from "../../../icons/event.png";
import whistleIcon from "../../../icons/whistle-footer.svg";
import shopIcon from "../../../icons/bag-nav.svg";
import locationIcon from "../../../icons/location-icon.svg";
import rightArrow from "../../../icons/right_Arrow.svg";
import findCoachIcon from "../../../icons/find-coach-icon.svg";

function HelpAndKMList() {
  const classes = useStyles();
  const [helpCollapse, setHelpCollapse] = useState(false);

  const handleHelpCollapse = () => {
    setHelpCollapse(!helpCollapse);
  };

  return (
    <>
      {/* <List classes={{ root: classes.muiListWrapper }}>
        <ListItem
          button
          classes={{ root: classes.subList }}
          component="a"
          // target="_blank"
          href="https://khelomore.com/sports-venues/login"
          rel="noopener noreferrer"
        >
          <span className={classes.icon}>
            <img src={userIcon} alt="user-icon" />
          </span>
          <ListItemText
            className={classes.subtitle2}
            classes={{ root: classes.listItemText }}
            primary="My Account"
          />
          <span className={classes.forwardIcon}>
            <strong className={classes.loginTag}>LOGIN</strong>
            <img
              className={classes.rightArrow}
              src={rightArrow}
              alt="right-arrow"
            />
          </span>
        </ListItem>
      </List> */}

      <List classes={{ root: classes.muiListWrapper }}>
        <ListItem
          button
          onClick={handleHelpCollapse}
          classes={{ root: classes.subList }}
          component="a"
          href="https://khelomore.com/sports-venues"
          rel="noopener noreferrer"
        >
          <span className={classes.icon}>
            <img className={classes.turfIcon} src={turfIcon} alt="turf-icon" />
          </span>

          <ListItemText
            className={classes.subtitle2}
            classes={{ root: classes.listItemText }}
            primary="Venues"
          />
          <span className={classes.forwardIcon}>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem>

        <ListItem
          button
          onClick={handleHelpCollapse}
          classes={{ root: classes.subList }}
          component="a"
          href="https://khelomore.com/sports-coaching/?category=at_home"
          rel="noopener noreferrer"
        >
          <span className={classes.icon}>
            <img src={whistleIcon} alt="coaching-icon" />
          </span>
          <ListItemText
            className={classes.subtitle2}
            classes={{ root: classes.listItemText }}
            primary="Coaching"
          />
          <span className={classes.forwardIcon}>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem>

        {/* <ListItem
          button
          onClick={handleHelpCollapse}
          classes={{ root: classes.subList }}
          component="a"
          href="https://www.khelomore.com/sports-coaching/one-on-one-personal-training"
          rel="noopener noreferrer"
        >
          <span className={classes.icon}>
            <img
              className={classes.newIcon}
              src={findCoachIcon}
              alt="coach-icon"
            />
          </span>
          <ListItemText
            className={classes.subtitle2}
            classes={{ root: classes.listItemText }}
            primary="Personal Training"
          />
          <span className={classes.forwardIcon}>
            <strong className={classes.newTagTwo}>NEW</strong>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem> */}

        <ListItem
          button
          onClick={handleHelpCollapse}
          classes={{ root: classes.subList }}
          component="a"
          href="https://www.khelomore.com/events/explore-events"
          rel="noopener noreferrer"
        >
          <span className={classes.icon}>
            <img className={classes.newIcon} src={eventIcon} alt="event-icon" />
          </span>
          <ListItemText
            className={classes.subtitle2}
            classes={{ root: classes.listItemText }}
            primary="Events"
          />
          <span className={classes.forwardIcon}>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem>

        <ListItem
          button
          onClick={handleHelpCollapse}
          classes={{ root: classes.subList }}
          component="a"
          href="https://shop.khelomore.com/"
          rel="noopener noreferrer"
        >
          <span className={classes.icon}>
            <img src={shopIcon} alt="shop-icon" />
          </span>
          <ListItemText
            className={classes.subtitle2}
            classes={{ root: classes.listItemText }}
            primary="Shop"
          />
          <span className={classes.forwardIcon}>
            <strong className={classes.newTag}>NEW!</strong>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem>
      </List>

      <List classes={{ root: classes.muiListWrapper }}>
        <ListItem
          button
          classes={{ root: classes.subList }}
          component="a"
          // target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSeHRjh5iIz-zS-iCeB1S8kPuosccr6MWV0ByZx4IAJ3nCfOVg/viewform"
          rel="noopener noreferrer"
        >
          <span className={classes.icon}>
            <img src={locationIcon} alt="location-icon" />
          </span>
          <ListItemText
            className={classes.subtitle2}
            classes={{ root: classes.listItemText }}
            primary="Register your venue"
          />
          <span className={classes.forwardIcon}>
            <strong className={classes.tryNowTxt}>TRY NOW</strong>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem>
      </List>
      <a href="https://tna61.app.link/ruFkgipnAsb">
        <img
          className={classes.registerBanner}
          src={RegisterBanner}
          alt="register-banner"
        />
      </a>
      <List classes={{ root: classes.muiListWrapper2 }}>
        <ListItem
          button
          className={classes.subList}
          component="a"
          href="https://blog.khelomore.com/"
          rel="noopener noreferrer"
        >
          <ListItemText
            className={classes.subtitle2}
            primary="Blogs and articles"
          />
          <span className={classes.forwardIcon}>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem>
        <ListItem
          button
          className={classes.subList}
          component="a"
          href="/terms-conditions"
          rel="noopener noreferrer"
        >
          <ListItemText
            className={classes.subtitle2}
            primary="Terms and Conditions"
          />
          <span className={classes.forwardIcon}>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem>
        <ListItem
          button
          className={classes.subList}
          component="a"
          href="/privacy-policy"
          rel="noopener noreferrer"
        >
          <ListItemText
            className={classes.subtitle2}
            primary="Privacy Policy"
          />
          <span className={classes.forwardIcon}>
            <img src={rightArrow} alt="right-arrow" />
          </span>
        </ListItem>
      </List>
    </>
  );
}

export default HelpAndKMList;
