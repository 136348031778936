import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  popularVenueHead: {
    padding: "29px 24px 12px 24px",
  },
  marginRight: {
    marginRight: "15px",
  },
  popularVenueText: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "30px",
    textTransform: "capitalize",
    letterSpacing: "0.105882px",
    color: "#212121",
  },
  viewAllBtn: {
    height: "40px",
    background: "rgba(71, 169, 255, 0.1)",
    borderRadius: "12px",
    padding: "8px",
  },
  viewAll: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#47A9FF",
  },
  scrollable: {
    overflowY: "hidden",
    padding: "0px 0px 24px 12px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  rightArrow: {
    marginLeft: "8px",
  },
  shoppingCard: {
    background: "#FFFFFF",
    margin: "15px 20px",
    borderRadius: "8px",
  },
  padding: {
    padding: "0px 0px 14px 24px",
  },
  shopCardContainer: {
    width: "220px",
    height: "259px",
  },
  mrBottom: {
    marginBottom: "16px",
  },
  shopCard: {
    background: "#FFFFFF",
  },
  shopImg: {
    width: "220px",
    height: "165px",
    border: "0.5px solid #F9F9F9",
    borderRadius: "8px 8px 0px 0px",
  },
  favIcon: {
    marginTop: "5px",
  },
  productHead: {
    fontWeight: 600,
    // whiteSpace: "nowrap",
    fontSize: "15px",
    lineHeight: "17px",
    color: "#424242",
    margin: "8px 0px 6px 8px",
  },
  productSubHead: {
    fontWeight: 400,
    whiteSpace: "nowrap",
    fontSize: "12px",
    lineHeight: "12px",
    color: "#484848",
    margin: "0px 0px 8px 8px",
  },
  productPrice: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "#424242",
    marginLeft: "8px",
  },
  productPriceOffer: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "0.0933333px",
    textDecorationLine: "line-through",
    color: "#FA1414",
    marginLeft: "6px",
  },
  buyBtn: {
    border: "1px solid #52BAFF",
    borderRadius: "2px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "0.72px",
    textTransform: "uppercase",
    color: "#19A3FF",
    padding: "5px 16px",
  },
  percentage: {
    display: "inline-block",
    height: "15px",
    borderRadius: "2px",
    background: "#FF4E00",
    padding: "3px",
    marginTop: "8px",
  },
  mRight: {
    marginRight: "6px",
  },
  percentageBox: {
    zIndex: 999,
    width: "100%",
    top: "5px",
    padding: "0px 8px",
  },
  percentageText: {
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "10px",
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
  ratingShop: {
    width: "32px",
    height: "16px",
    background: "#04BA07",
    borderRadius: "2px",
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    bottom: "10px",
    left: "8px",
  },
  shopStarIcon: {
    width: "10px",
    height: "10px",
    margin: "-2px 0px 0px 2px",
  },
  hr: {
    margin: 0,
    color: "#98A0A2",
    border: "none",
    borderTop: "1px solid #EDF3FD",
  },
  locationTag: {
    overflowY: "hidden",
    whiteSpace: "nowrap",
    padding: "12px 0px 16px 24px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  locationButtonActive: {
    border: "none",
    padding: "10px 13px",
    marginRight: "8px",
    background: "rgba(0, 126, 51, 0.1)",
    border: "1px solid #007E33",
    borderRadius: "16px",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "0.4px",
    color: "#007E33",
  },
  locationButton: {
    border: "none",
    padding: "10px 13px",
    marginRight: "8px",
    background: "#FFFFFF",
    border: "1px solid #B3B3B3",
    borderRadius: "16px",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "0.4px",
    color: "#424242",
  },
  fullWidth: {
    width: "100%",
  },
  productImage: {
    width: "220px",
    height: "165px",
    border: "0.5px solid #ececec",
    borderRadius: "8px 8px 0px 0px",
  },
  "@media (max-width: 550px)": {
    padding: {
      padding: "0px 16px 10px 16px",
    },
    shopCardContainer: {
      width: "100%",
      height: "auto",
    },
    shoppingCard: {
      background: "#FFFFFF",
      margin: "8px 0px",
    },
    scrollable: {
      padding: "0px 0px 30px 0px",
    },
    mrBottom: {
      marginBottom: "16px",
    },
    shoppingCard: {
      background: "#FFFFFF",
      margin: "8px 0px",
    },
    shopCard: {
      width: "154px",
      height: "188px",
      background: "#FFFFFF",
      marginRight: "19px",
    },
    shopImg: {
      width: "154px",
      height: "116px",
      borderRadius: "8px",
      border: "0.5px solid #f2e9e9",
    },
    fullWidth: {
      width: "100%",
    },
    productHead: {
      fontWeight: 600,
      // whiteSpace: "nowrap",
      fontSize: "12px",
      lineHeight: "12px",
      color: "#424242",
      margin: "8px 0px 5px 0px",
    },
    productSubHead: {
      fontWeight: 400,
      whiteSpace: "normal",
      fontSize: "10px",
      lineHeight: "10px",
      color: "#484848",
      margin: "0px 0px 3px 0px",
    },
    productPrice: {
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0.0933333px",
      color: "#424242",
      marginLeft: "0px",
    },
    productPriceOffer: {
      fontWeight: 500,
      fontSize: "8px",
      lineHeight: "8px",
      letterSpacing: "0.0933333px",
      textDecorationLine: "line-through",
      color: "#FA1414",
      marginLeft: "6px",
    },
    buyBtn: {
      border: "1px solid #52BAFF",
      borderRadius: "2px",
      fontWeight: 700,
      fontSize: "10px",
      lineHeight: "9px",
      letterSpacing: "0.72px",
      textTransform: "uppercase",
      color: "#19A3FF",
      padding: "5px 15px",
      marginLeft: "0px",
      marginRight: "15px",
    },
    percentage: {
      display: "inline-block",
      height: "15px",
      borderRadius: "2px",
      background: "#FF4E00",
      padding: "3px",
      marginTop: 0,
    },
    percentageBox: {
      zIndex: 99,
      width: "100%",
      top: "5px",
      padding: "0px 5px",
    },
    percentageText: {
      fontWeight: 600,
      fontSize: "10px",
      lineHeight: "10px",
      textTransform: "uppercase",
      color: "#FFFFFF",
    },
    percentageIcon: {
      marginTop: "-2px",
    },
    ratingShop: {
      width: "auto",
      padding: "4px",
      height: "15px",
      background: "#007E33",
      borderRadius: "2px",
      fontWeight: 600,
      fontSize: "10px",
      lineHeight: "10px",
    },
    shopStarIcon: {
      width: "10px",
      height: "10px",
      margin: "-2px 0px 0px 2px",
    },
    popularVenueHead: {
      padding: "18px 18px 10px 16px",
    },
    popularVenueText: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "16px",
      textTransform: "capitalize",
      color: "#212121",
    },
    hr: {
      margin: 0,
      color: "#98A0A2",
      border: "none",
      borderTop: "1px solid #EDF3FD",
    },
    locationTag: {
      overflowY: "hidden",
      whiteSpace: "nowrap",
      padding: "12px 0px 16px 16px",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&::-moz-scrollbars": {
        display: "none",
      },
    },
    locationButtonActive: {
      border: "none",
      padding: "10px",
      marginRight: "8px",
      background: "rgba(0, 126, 51, 0.05)",
      border: "1px solid #007E33",
      borderRadius: "16px",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "12px",
      letterSpacing: "0.4px",
      color: "#007E33",
    },
    locationButton: {
      border: "none",
      padding: "10px",
      marginRight: "8px",
      background: "#FFFFFF",
      border: "1px solid #B3B3B3",
      borderRadius: "16px",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "12px",
      letterSpacing: "0.4px",
      color: "#424242",
    },
    productImage: {
      width: "154px",
      height: "116px",
    },
  },
  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  alignCenter: {
    alignItems: "center",
  },
  flexCol: {
    flexDirection: "column",
  },

  justifyCenter: {
    justifyContent: "center",
  },
  justifyEnd: {
    justifyContent: "end",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  alignEnd: {
    alignItems: "end",
  },
  alignBase:{
    alignItems:"baseline"
  }
}));

export default style;
