import { React, useState } from "react";
import useStyles from "./styles";
import clsx from "clsx";
import SportsVenues from "./SearchVenues/SportsVenues";
import HomeTraining from "./SearchVenues/HomeTraining";
import DeskCarouselCard from "./DeskCarouselCard";

export default function DesktopCarousel({ data, loading}) {
  const classes = useStyles();
  const [isActive, setActive] = useState(true);
  const [selectedHomeTraining, setselectedHomeTraining] = useState(false);

  const homeTrainingHandler = (param) => {
    setselectedHomeTraining(param);
  };

  const sportsVenuesHandler = () => {
    setActive(!isActive);
    setselectedHomeTraining(false);
  };

  return (
    <div className={classes.relative}>
      {/* {loading ? 
          <div style={{width:"100%", height:"580px", background:"#EAEAEA" }}> </div>
       : 
      <DeskCarouselCard carouselData={data} />
      } */}
      <div
        className={clsx(
          classes.carouselCard,
          classes.flex,
          classes.alignCenter,
          classes.justifyCenter
        )}
      >
        <div
          className={clsx(
            classes.searchContainer,
            classes.flex,
            classes.flexCol
          )}
        >
          <div className={classes.noWrap}>
            {/* <p className={classes.topText}>I’m looking for</p> */}
            <button
              onClick={sportsVenuesHandler}
              className={
                isActive ? classes.carouselActiveBtn : classes.carouselBtn
              }
            >
              <span className={isActive ? classes.bottomLine : null}>
              PLAY SPORTS NEAR YOU
              </span>
            </button>
            <button
              onClick={sportsVenuesHandler}
              className={clsx(
                classes.mLeft,
                !isActive ? classes.carouselActiveBtn : classes.carouselBtn
              )}
            >
              <span className={!isActive ? classes.bottomLine : null}>
              GET SPORTS COaching              
              </span>
            </button>
          </div>
          <div>
            {isActive ? (
              <SportsVenues />
            ) : (
              <HomeTraining handler={homeTrainingHandler} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
