import { gql } from "@apollo/client";

export const HERO_CAROUSELS = gql`
query heroCarousels {
  heroCarousels {
    data {
      attributes {
        imageURL
      }
    }
  }
}
`;

export const GET_ALL_POPULAR_VENUES = gql`
  query getAllPopularVenues {
    popularVenues(pagination: { limit: 100 }) {
      data {
        attributes {
          name
          overallRating
          numberOfRatings
          locality
          kmExclusive
          discountInPercentage
          cityName
          venueURL
          popularTag
          amenities
          imageURL
          venueOfMonthImgURL
        }
      }
    }
  }
`;

export const FAVOURITES = gql`
query Favourites {
  playersFavourites {
    data {
      attributes {
        title
        totalVenues
        URL
        imageURL
      }
    }
  }
}
`;

export const VENUE_LISTING = gql`
query venueListing {
  listYourVenues {
    data {
      attributes {
        nameOfVenue
        imageURL
      }
    }
  }
}
`;
export const TRENDING_POPULAR_BANNER = gql`
  query trendingpopularBanner {
    trendingAndPopulars {
      data {
        attributes {
          imageURL
          productURL
        }
      }
    }
  }
`;

export const TRAINING_BY_COACHES = gql`
  query trainingByCoaches {
    getTrainedAtHomes(pagination: { limit: 100 }) {
      data {
        attributes {
          nameOfTheCamp
          academyName
          coachName
          level
          overallRating
          numberOfRatings
          minimumAge
          maximumAge
          mode
          cardURL
          imageURL
          address
          liveBatches
        }
      }
    }
  }
`;
export const LOCATIONS = gql`
  query allLocations {
    locations(pagination: { limit: 100 }) {
      data {
        attributes {
          location
        }
      }
    }
  }
`;

export const SHOP_CATEGORY = gql`
  query shopCategory {
    shopCategories(pagination: { limit: 100 }) {
      data {
        attributes {
          shopCategory
        }
      }
    }
  }
`;
export const SHOPPING_CARD = gql`
query shoppingCard {
  shoppingCards(pagination: { limit: 100 }) {
    data {
      attributes {
        productName
        productDescription
        discountInPercentage
        actualPrice
        offerPrice
        overallRating
        sportCategory
        buyURL
        imageURL  
      }
    }
  }
}
`;

export const BLOGS_ARTICLES = gql`
  query blogsAndArticles {
    blogsAndArticles {
      data {
        attributes {
          Title
          blogURL
          imageURL
        }
      }
    }
  }
`;

export const INSTA= gql`
query instagram {
  instagrams {
    data {
      attributes {
        imageURL
        instaURL
      }
    }
  }
}
`

export const DESK_BANNER = gql`
query deskBanner {
  deskBanners {
    data {
      attributes {
        imageURL
      }
    }
  }
}
`;

export const MOBILE_BANNER = gql`
query mobileBanner {
  mobileBanners {
    data {
      attributes {
      imageURL
      }
    }
  }
}
`
;


export const About_KM = gql`
query aboutKM {
  aboutKms {
    data {
      attributes {
        Value
      }
    }
  }
}
`
;

export const FAQ = gql`
query FAQ {
  faqs {
    data {
      attributes {
        Value
      }
    }
  }
}
`
;

export const CAREER = gql`
query career {
  careers {
    data {
      attributes {
        Value
      }
    }
  }
}
`
;

export const PARTNERSHIP = gql`
query partnership {
  partnerships {
    data {
      attributes {
        Value
      }
    }
  }
}
`
;

export const PRICING_AND_REFUND = gql`
query pricingAndRefund {
  pricingAndRefunds {
    data {
      attributes {
        Value
      }
    }
  }
}
`
;

export const PRIVACY_POLICY = gql`
query privacyPolicy {
  privacyPolicies {
    data {
      attributes {
        Value
      }
    }
  }
}
`
;

export const TERMS_AND_CONDITIONS = gql`
query termsAndCondition {
  termsAndConditions {
    data {
      attributes {
        Value
      }
    }
  }
}
`
