import { makeStyles } from "@material-ui/core/styles";
import bgImage from "../../images/rotate_bg.png";
import deskBgImage from "../../images/desLearn_Bg.svg";


const style = makeStyles((theme) => ({

    insideContainer:{
        backgroundColor: "#0075CB",
        backgroundImage: `url(${deskBgImage })`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundPositionY: "inherit",
        borderRadius: "8px",
        padding: "24px 0px 46px 0px"

    },
    commonMargin:{
        margin:"15px 20px"
       },
     scrollContainer:{
            overflowY: "hidden",
            padding: "0px 0px 0px 60px",
            msOverflowStyle:"none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
            "&::-moz-scrollbars": {
                display: "none",
              },
    },
    learnIcon:{
        width:"64px",
        height:"64px",
        marginBottom:"8px"
    },
    learnHead:{
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "30px",
        letterSpacing: "0.105882px",
        color: "#FFFFFF",
        paddingLeft:"24px",
    },
    learnCard:{
        marginTop: "40px",
        marginRight: "30px",
        width:"153px",
        height:"auto"
    },      
    textWidth:{
        width:"114px"
    }, 
    learnSubHead:{
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "20px",
        textAlign: "left",
        color: "#FFFFFF",
        padding:"0px 0px",

    },
    noWrap:{
        whiteSpace:"nowrap",
    },
    mrRight:{
        marginRight:"20px"
    },
    rightArrow:{
        marginLeft:"12px",
        height:"14px"
    },
    popularTag:{
        height: "14px",
        fontWeight: 700,
        fontSize: "9px",
        lineHeight: "14px",
        color: "#FFFFFF",
        backgroundColor:"#FF4E00",
        padding:"6px",
        whiteSpace:"nowrap",
        borderRadius: "14px",
        top:"-7px",
        left:"-7px"
    },
    newTag:{
        width: "32px",   
        height: "14px",
        fontWeight: 700,
        fontSize: "9px",
        lineHeight: "14px",
        color: "#FFFFFF",
        backgroundColor:"#F7B829",
        padding:"4px",
        whiteSpace:"nowrap",
        borderRadius: "14px",
        top:"-7px",
        left:"16px"

    },
    relative:{
        position:"relative",
    },
    absolute:{
        position:"absolute"
    },
    "@media (max-width:550px)":{
        learnContainer:{
            background: "#FFFFFF",
            padding: "12px 16px",
        },
        insideContainer:{
            backgroundColor: "#0075CB",
            backgroundImage: `url(${bgImage })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            backgroundPositionY: "inherit",
            borderRadius: "8px",
            padding: "16px 21px 20px 21px",
        },
        learnHead:{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "16px",
            textTransform: "capitalize",
            color: "#FFFFFF",
            paddingLeft:0
        },
        learnCard:{
            marginTop: "22px",
            marginRight:"0px",
            width:"68px"
        },       
        learnSubHead:{
            width: "69px",
            height: "26px",
            fontWeight: 600,
            fontSize: "10px",
            lineHeight: "13px",
            textAlign: "center",
            color: "#FFFFFF",
            padding: "0px 4px"
        },
        popularTag:{
            height: "14px",
            fontWeight: 700,
            fontSize: "9px",
            lineHeight: "14px",
            color: "#FFFFFF",
            backgroundColor:"#FF4E00",
            padding:"4px",
            whiteSpace:"nowrap",
            borderRadius: "14px",
            top:"-7px",
            left:"-2px"
        },
        newTag:{
            width: "32px",   
            height: "14px",
            fontWeight: 700,
            fontSize: "9px",
            lineHeight: "14px",
            color: "#FFFFFF",
            backgroundColor:"#F7B829",
            padding:"4px",
            whiteSpace:"nowrap",
            borderRadius: "14px",
            top:"-7px",
            left:"19px"
        },
        learnIcon:{
            width:"42px",
            height:"42px"
        },
      },
      flex:{
        display:"flex"
    },
    flexWrap:{
        flexWrap:"wrap"
    }, 
    spaceBw:{
        justifyContent:"space-between"
    },
    alignCenter:{
        alignItems: "center"
    },
    flexCol:{
        flexDirection:"column"
    },
    justifyCenter:{
        justifyContent: "center"
    },
    relative:{
        position: "relative"
    },
    absolute:{
        position: "absolute"
    },
    alignEnd:{
        alignItems: "end"
    },
    flex25:{
        flex: "0 1 25%"
    }
}));

export default style;