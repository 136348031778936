import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../theme/common.styles";

const useStyles = makeStyles((theme) => ({
  list: {
    borderBottom: `1px solid ${theme.palette.whiteGrey}`,
    letterSpacing: "0.15px",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  subtitle2: {
    "& span": {
      ...commonStyles.subtitle4,
      color: theme.palette.baseColor,
      lineHeight: 1,
    },
  },
  loginTag: {
    ...commonStyles.subtitle7,
    fontWeight: "700",
    padding: "2px 4px",
    lineHeight: "10px",
    letterSpacing: "1px",
    background: "#EA4A2F",
    color: "#FFFFFF",
    textTransform: "uppercase",
    marginRight: "20px",
    borderRadius: "2px",
  },
  newTag: {
    ...commonStyles.subtitle7,
    fontWeight: "700",
    padding: "2px 4px",
    lineHeight: "10px",
    letterSpacing: "1px",
    background: "rgba(0, 126, 51, 0.1)",
    color: "#007E33",
    textTransform: "uppercase",
    marginRight: "20px",
    borderRadius: "2px",
  },
  newTagTwo: {
    background: "linear-gradient(90deg, #2C89CD 0%, #9523BD 100%)",
    padding: "2px 4px",
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "8px",
    lineHeight: "8px",
    letterSpacing: "0.015em",
    color: "#FFFFFF",
    marginLeft: "4px",
    display: "inline-block",
    // position:"relative",
    // top:"-2px",
    marginRight: "20px",
  },
  tryNowTxt: {
    ...commonStyles.subtitle7,
    fontWeight: "700",
    padding: "2px 4px",
    lineHeight: "10px",
    letterSpacing: "1px",
    background: "#007E33",
    color: "#FFFFFF",
    textTransform: "uppercase",
    marginRight: "20px",
    borderRadius: "2px",
  },

  rightArrow: {
    color: "#BDBDBD",
  },
  registerBanner: {
    width: "100%",
    height: "auto",
    padding: "8px 16px 0px 16px",
  },
  subList: {
    padding: 16,
    borderBottom: `1px solid ${theme.palette.whiteGrey}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },

  fw600: {
    "& span": {
      fontWeight: 600,
    },
  },
  muiListWrapper: {
    margin: "8px 16px 0 16px",
    border: `1px solid ${theme.palette.whiteGrey}`,
    borderRadius: 8,
    padding: 0,
  },
  muiListWrapper2: {
    padding: 0,
    margin: "0px 16px",
  },
  forwardIcon: {
    lineHeight: 0,
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      fill: theme.palette.greyColor,
      height: 16,
      width: 15,
    },
  },
  icon: {
    lineHeight: 0,
    marginRight: "8px",
    "& svg": {
      height: 18,
      width: 18,
    },
  },
  newIcon: {
    height: 24,
    width: 24,
  },
  listItemText: {
    margin: 0,
  },
  turfIcon: {
    height: "24px",
    width: "24px",
  },
}));

export default useStyles;
