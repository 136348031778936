import React from "react";
import {
   ApolloClient,
   InMemoryCache,
   ApolloProvider,
 } from "@apollo/client"; 
import { ThemeProvider } from "@material-ui/core";
import SeoManager from "./seoManager";
import { BrowserRouter, Route, Redirect, Routes } from "react-router-dom";
import theme from "../theme/theme";
import Main from "./Main";
import TermsConditions from "./Footer/FooterSections/Terms&Conditions";
import AboutKM from "./Footer/FooterSections/AboutKM";
import Partnerships from "./Footer/FooterSections/Partnerships";
import Career from "./Footer/FooterSections/Career";
import PricingAndRefund from "./Footer/FooterSections/PricingAndRefund";
import PrivacyPolicy from "./Footer/FooterSections/PrivacyPolicy";
import FAQs from "./Footer/FooterSections/FAQs";


const client = new ApolloClient({
   uri: `${process.env.REACT_APP_API_URL}`,
   cache: new InMemoryCache()
 });

export default function App() {
   return (
      <ApolloProvider client={client} >
         <SeoManager />
         <ThemeProvider theme={theme}>
            <BrowserRouter>
            <Routes>
               <Route path="/" element={<Main />} />
               <Route path="/terms-conditions" element={<TermsConditions />} />
               <Route path="/about-us" element={<AboutKM />} />
               <Route path="/partnerships" element={<Partnerships />} />
               <Route path="/careers" element={<Career />} />
               <Route path="/pricing-policy" element={<PricingAndRefund />} />
               <Route path="/privacy-policy" element={<PrivacyPolicy />} />
               <Route path="/faq" element={<FAQs />} />
            </Routes>
            </BrowserRouter>
         </ThemeProvider>
      </ApolloProvider>
      
   )
}