import { React} from "react";


 const BlogLoader = () =>{
  return(
    <div>
      {

      <div style={{
        display:"flex",
        }} >
        <div>
          <div style={{
            width: "324px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
          <div style={{
             width: "324px",
             height: "20px",
             margin:"20px 0px 5px",
             borderRadius: "16px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
        </div>  

        <div>
          <div style={{
            width: "324px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
          <div style={{
             width: "324px",
             height: "20px",
             margin:"20px 0px 5px",
             borderRadius: "16px",
             backgroundColor:"#EAEAEA"
          }}>
          </div>
        </div>  

        <div>
          <div style={{
            width: "324px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
          <div style={{
             width: "324px",
             height: "20px",
             margin:"20px 0px 5px",
             borderRadius: "16px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
        </div>  
        <div>
          <div style={{
            width: "324px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
          <div style={{
             width: "324px",
             height: "20px",
             margin:"20px 0px 5px",
             borderRadius: "16px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
        </div>  
        <div>
          <div style={{
            width: "324px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
          <div style={{
             width: "324px",
             height: "20px",
             margin:"20px 0px 5px",
             borderRadius: "16px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
        </div>  
        <div>
          <div style={{
            width: "324px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
          <div style={{
             width: "324px",
             height: "20px",
             margin:"20px 0px 5px",
             borderRadius: "16px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
        </div>  
      </div>               
    }
    </div>
  )
}

export default BlogLoader;

