import React from "react";
import Backdrop from "./Backdrop";
import SidePanel from "./SidePanel";

function RightDrawer(props) {
  const { isSidePanelOpen, closePanel, children } = props;

  return (
    <>
      <Backdrop isSidePanelOpen={isSidePanelOpen} closePanel={closePanel} />
      <SidePanel isSidePanelOpen={isSidePanelOpen} closePanel={closePanel}>
        {children}
      </SidePanel>
    </>
  );
}

export default RightDrawer;
