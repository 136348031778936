import React from "react";
import { useQuery } from "@apollo/client";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { MOBILE_BANNER } from "../../gqlOperations/queries";
import useStyles from "./styles";
import MobAtHomeLoader from "../skeletonLoader/MobAtHomeLoader";

export default function MobileRegisterBanner() {
  const classes = useStyles();
  const { loading, error, data } = useQuery(MOBILE_BANNER);
  const imgData = data?.mobileBanners.data.map(
    (detail, idx) => detail.attributes.imageURL
  );

  const newCarouselData = [
    {
      imageURL:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/rs-mob-banner.svg",
      linkURL:
        "https://www.khelomore.com/sports-coaching/society-sports-coaching",
    },
    {
      imageURL:
        "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/event-mob-banner.svg",
      linkURL:
        "https://www.khelomore.com/events/explore-events/list-events-tournaments",
    },
  ];

  return (
    <>
      <div className={classes.homeBannerCard}>
        {loading ? (
          <MobAtHomeLoader />
        ) : (
          <div>
            <Carousel
              autoPlay
              infiniteLoop
              showIndicators={newCarouselData?.length >= 1}
              showStatus={false}
              showThumbs={false}
              interval={3000}
              showArrows={false}
            >
              {newCarouselData?.map((data, idx) => (
                <a
                  href={data?.linkURL}
                  className={classes.carouselBannerDiv}
                  key={idx}
                >
                  <img style={{}} src={data?.imageURL} alt="carousel" />
                </a>
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </>
  );
}
