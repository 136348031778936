import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { SHOPPING_CARD } from "../../gqlOperations/queries";
import { SHOP_CATEGORY } from "../../gqlOperations/queries";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import useStyles from "./styles";
import MobileCategory from "./MobileCategory";
import DeskCategory from "./DeskCategory";
import DeskShopCard from "./DeskShopCard";
import MobileShopCard from "./MobileShopCard";

const RenderShopCard = () => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(SHOPPING_CARD);

  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  } = useQuery(SHOP_CATEGORY);

  const [currentCartData, setCurrentCartData] = useState(
    data?.shoppingCards?.data
  );
  const [isActiveCategory, setActiveCategory] = useState();

  useEffect(() => {
    const filteredData = data?.shoppingCards?.data?.filter(
      (obj) =>
        obj?.attributes?.sportCategory?.toLowerCase() === "all"
    );
    if (filteredData?.[0]) setCurrentCartData(filteredData);
    // setCurrentCartData(data?.shoppingCards?.data);
    handleSelectCategory("all");
  }, [data]);
  // if (category?.toLowerCase() === "all") {
  //   setCurrentCartData(data?.shoppingCards?.data);
  //   setActiveCategory("all");
  // } else
  const handleSelectCategory = (category) => {
  //  if (category === isActiveCategory) {
  //     setActiveCategory("");
  //     setCurrentCartData(data?.shoppingCards?.data);
  //   } else {
      setActiveCategory(category);
      const filteredData = data?.shoppingCards?.data?.filter(
        (obj) =>
          obj?.attributes?.sportCategory?.toLowerCase() ===
          category?.toLowerCase()
      );
      if (filteredData?.[0]) setCurrentCartData(filteredData);
      else setCurrentCartData([]);
  };

  const shoppingCardData = currentCartData?.map(
    (details) => details.attributes
  );

  const categories = categoryData?.shopCategories?.data?.map(
    (category) => category.attributes
  );

  return (
    <div className={classes.shoppingCard}>
      {isMobile ? (
        <MobileCategory
          category={categories}
          handleSelectCategory={handleSelectCategory}
          isActiveCategory={isActiveCategory}
          loading = {categoryLoading}
        />
      ) : (
        <DeskCategory
          category={categories}
          handleSelectCategory={handleSelectCategory}
          isActiveCategory={isActiveCategory}
          loading = {categoryLoading}
        />
      )}
      <div className={clsx()}>
        {isMobile ? (
          <MobileShopCard cardData={shoppingCardData} loading={loading} />
        ) : (
          <DeskShopCard cardData={shoppingCardData} loading={loading} />
        )}
      </div>
    </div>
  );
};

export default RenderShopCard;
