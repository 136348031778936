import {makeStyles} from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
    
            popularVenueHead:{
                padding: "29px 24px 16px 24px"
            },
            popularVenueText:{
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "30px",
                color: "#212121",
                marginTop:"-10px"
            },
            viewAllBtn:{
                height: "40px",
                background: "rgba(71, 169, 255, 0.1)",
                borderRadius: "12px",
                display:"inline-block",
                padding:"8px"
            },
            viewAll:{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#47A9FF",
            },
            rightArrow:{
                marginLeft:"8px"
            },
            hr:{
                margin: 0,
                color: "#98A0A2",
                border: "none",
                borderTop: "1px solid #EDF3FD",
             },
             locationTag:{
                marginLeft:"24px",
            },
            locationButtonActive:{
                border: "none",
                padding: "10px",
                marginRight: "8px",
                background: "rgba(0, 126, 51, 0.05)",
                border: "1px solid #007E33",
                borderRadius: "16px",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "12px",
                letterSpacing: "0.4px",
                color: "#007E33",
                marginBottom:"10px"
            },
            locationButton:{
                border: "none",
                padding: "10px",
                marginRight: "8px",
                background: "#FFFFFF",
                border: "1px solid #B3B3B3",
                borderRadius: "16px",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "12px",
                letterSpacing: "0.4px",
                color: "#424242",
                marginBottom:"10px"
            },
            listCardContainer:{
                overflowY: "hidden",
                padding: "0px 0px 12px 12px",
                msOverflowStyle:"none",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
                "&::-moz-scrollbars": {
                    display: "none",
                  },
            },
            venueCard:{
                width: "380px",
                background: "#FFFFFF",
                // border: "1px solid #EEEEEE",
                borderRadius: "8px",
                padding: "8px",
                marginRight: "8px",
            },
            listCardHeadText:{
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "15px",
                color: "#212121",
                textTransform:"uppercase",
                whiteSpace:"nowrap"
            },
            listText:{
                fontWeight: 500,
                fontSize: "11px",
                lineHeight: "12px",
                letterSpacing: "0.4px",
                color: "#424242",
                marginTop:"8px",
            },
            rating:{
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "12px",
                letterSpacing: "0.4px",
                color: "#212121"
            },
            starIcon:{
                width: "8.5px",
                height: "8.5px",
                background: "#FFFFFF",
                marginRight: "2.5px"
            },
            review:{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "12px",
                letterSpacing: "0.4px",
                color: "#424242",
                marginLeft: "6px"
            },
            favExclusive:{
                top: "8px",
                padding: "8px 8px"
            },
            exclusiveBox:{
                background: "#212121",
                marginBottom:"8px",
                opacity: 0.8,
                mixBlendMode: "normal",
                borderRadius: "2px",
                padding:"3px 0px",
                width: "72px",
                height: "14.5px",
            },
            exclusive:{
                fontWeight: 600,
                fontSize: "7px",
                lineHeight: "6px",
                letterSpacing: "1px",
                textTransform: "uppercase",
                color: "#fff"
            },
            mRight:{
                marginRight:"6px"
            },
            listImgBox:{
                width: "100%"
            },
            venueOfWeek:{
                width: "72px",
                height: "25px",
                top: "150px",
                bottom: "0px",
                left: "230px",
                right: "0",
                padding: "5px",
                textAlign: "center",
                margin: "0 auto",
            },
            venueDetails:{
                whiteSpace: "nowrap",
                fontWeight: 600,
                fontSize: "10.5px",
                lineHeight: "10.5px",
                letterSpacing: "0.4px",
                color: "#212121",
                padding: "0px 6px"
            },
            venueImg:{
                width: "370px",
                height: "182px",
                objectFit: "cover",
                borderRadius: "16px",
                margin: "8px 0px"
            },
            venueSportIcon:{
                marginLeft: "5px"
            },
            flex:{
                display:"flex"
            }, 
            spaceAround:{
                justifyContent:"space-around"
            },
            spaceBw:{
                justifyContent:"space-between"
            },
            alignCenter:{
                alignItems: "center"
            },
            flexCol:{
                flexDirection:"column"
            },
            justifyCenter:{
                justifyContent: "center"
            },
            relative:{
                position: "relative"
            },
            absolute:{
                position: "absolute"
            },
            alignEnd:{
                alignItems: "end"
            },
            nowWrap:{
                whiteSpace  :"nowrap"
            },
            preWrap:{
                whiteSpace: "pre",
            },
            popularVenu:{
                background: "#ffffff",
                margin:"15px 20px",
                borderRadius:"8px"
            },
            percentage: {
                display: "inline-block",
                height: "15px",
                borderRadius: "2px",
                background: "#FF4E00",
                padding: "3px",
                marginBottom:"8px",
              },
              percentageText: {
                fontWeight: 600,
                fontSize: "10px",
                lineHeight: "10px",
                textTransform: "uppercase",
                color: "#FFFFFF"
              },
              percentageIcon:{
                marginTop:"-2px"
            },
            popularTag:{
                background: "#FFBB33",
                borderRadius: "2px",
                width:"auto",
                height:"14px",
                padding:"4px",
            },
            popularText: {
                fontWeight: 600,
                fontSize: "10px",
                lineHeight: "10px",
                textTransform: "uppercase",
                color:"#000000",
                letterSpacing:"1.2px",

              },
              slicedText: {
                width: "320px",
                overflow: "hidden"
              },
             
            "@media (max-width: 550px)":{
                popularVenu:{
                    background: "#ffffff",
                    margin:"0"
                },
                popularVenueHead:{
                    padding: "18px 18px 10px 16px"
                },
                percentageIcon:{
                    marginTop:"-2.5px"
                },
                popularVenueText:{
                    fontSize:16,
                    marginTop:"0px"
                },
                venueCard: {
                    width: "340px",
                },
                locationButtonActive:{
                    marginBottom:"0px"
                },
                locationButton:{
                    marginBottom:"0px"
                },
                venueImg:{
                    width: "330px",
                    height: "168.5px",
                    objectFit: "cover",
                    borderRadius: "16px",
                    margin: "8px 0px"
                },
                venueOfWeek:{
                    width: "72px",
                    height: "25px",
                    top: "140px",
                    bottom: "0px",
                    left: "195px",
                    right: "0",
                    padding: "5px",
                    textAlign: "center",
                    margin: "0 auto",
                },
                locationTag:{
                    overflowY: "hidden",
                    whiteSpace: "nowrap",
                    margin:"0",
                    padding: "12px 0px 16px 18px",
                    msOverflowStyle:"none",
                    scrollbarWidth: "none",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    "&::-moz-scrollbars": {
                        display: "none",
                      },
                },
                listCardContainer:{
                    overflowY: "hidden",
                    padding: "0px 8px 20px 16px",
                    msOverflowStyle:"none",
                    scrollbarWidth: "none",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    "&::-moz-scrollbars": {
                        display: "none",
                      },
                },
                slicedText: {
                    width: "270px",
                    overflow: "hidden"
                  },
            }

}))

export default style; 




