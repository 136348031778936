export const deskKmGalleryData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/kmGOne.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/kmGTwo.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/kmGThree.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/kmGFour.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/kmGFive.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/kmGSix.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/kmGSeven.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/kmGEight.png",
  },
];

export const mobileKmGalleryData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/newVenueFball.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/newVenueCricket.png",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/newVenueBdmn.png",
  },
];