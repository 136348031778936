import React from "react";

import { isMobile } from "react-device-detect";
import DeskAppBanner from "./DeskAppBanner";
import MobileAppBanner from "./MobileAppBanner";

export default function AppBanners(){
  return(
    <>
      {
        isMobile ? <MobileAppBanner /> : < DeskAppBanner />
      }
    </>
  )
}
