import React from "react";
import { isMobile } from "react-device-detect";
import { useQuery } from "@apollo/client";
import DesktopHeader from "../../Header/DesktopHeader";
import MobileHeader from "../../Header/MobileHeader";
import DeskFooter from "../DeskFooter";
import MobileFooter from "../MobileFooter";

import { TERMS_AND_CONDITIONS } from "../../../gqlOperations/queries";

export default function TermsConditions(){
  document.title = "Terms and Conditions | KheloMore";
  const {loading, error , data} = useQuery(TERMS_AND_CONDITIONS);
  const htmlData = data?.termsAndConditions?.data.map((details) => details?.attributes?.Value);
  return(
    <>
    {isMobile ? 
    <div>
      <MobileHeader/>
      <div>
      <div dangerouslySetInnerHTML={{__html: htmlData}}/>
      </div>
      {/* <MobileFooter />     */}
    </div> 
    : 
    <div>
      <DesktopHeader/>
      <div>
      <div dangerouslySetInnerHTML={{__html: htmlData}}/>
      </div>
      <DeskFooter />    
    </div>
    }
    </>
  )
}
