import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../theme/common.styles";
import bgIcon from "../../icons/select-arrow.svg";


const style = makeStyles((theme) => ({
  scrollable: {
    width: "377px",
    height: "56px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #C9CDD5",
    borderRadius: "8px",
    margin: "24px 0px 24px -10px",
    color: "#424242",
    ...commonStyles.title3,
    lineHeight: "22px",
    paddingLeft: "50px",
    appearance: "none",
    outline: "none",
    backgroundPosition: "calc(100% - 25px) center !important",
    background: `url(${bgIcon}) no-repeat`,
    "&::-webkit-appearance": {
      display: "none",
    },
    "&::-moz-appearance": {
      display: "none",
    },
    "&::-ms-appearance": {
      display: "none",
    },
  },

  locationIcon: {
    position: "relative",
    left: "18px",
  },
  input: {
    width: "377px",
    height: "46px",
    background: "#FFFFFF",
    border: "1px solid #eee",
    borderRadius: "15px",
    marginLeft: "-18px",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "22px",
    paddingLeft: "50px",
    outline: "none",
    color: "#424242",
    "&::placeholder":{
      color:"#A9A7A7"
    }
  },
  searchIcon: {
    position: "relative",
    left: "15px",
    color: "lightgray",
  },
  homeTraining: {
    height: "52px",
    background: "#FFFFFF",
    border: "1px solid #D8DEE8",
    borderRadius: "8px",
    margin: "20px 0px 16px 0px",
    color: "#424242",
    ...commonStyles.title3,
    paddingLeft: "30px",
  },
  searchIcon: {
    position: "relative",
    top: "15px",
    left: "22px",
    width: "14px",
    height: "14px",
  },
  serachInput: {
    // margin: "24px 0px 0px -8px",
  },
  searchBtn: {
    padding: "12px 16px",
    background: "#ff4e00",
    borderRadius: "14px",
    width: "100%",
    // margin: "50px 0px 40px 0px",
    cursor: "default",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "17px",
    marginLeft:"12px",
    height:"46px",
  },
  activeSearchBtn: {
    background: "#ff4e00",
    cursor: "pointer",
  },
  searchBtnText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "16px",
    color: "#FFFFFF",
    cursor: "default",
  },

  activeSearchBtnText: {
    cursor: "pointer",
  },
  bottomLine:{
    width:"70%",
    display:"inline-block",
    borderBottom:"2px solid #FF4E00",
    paddingBottom:"10px"
  },
  homeTrainingBtn: {
    height: "46px",
    width: "265px",
    background: "#FFFFFF",
    border: "1px solid #eee",
    borderRadius: "14px",
    fontSize:"15px",
    fontWeight:"700",
    lineHeight: "17px",
    color: "#424242",
    mixBlendMode: "normal",
    marginRight: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deskBtn:{
    margin:"0 auto"
  },
  homeTrainingActiveBtn: {
    background: "#FF4E00",
    height: "46px",
    width: "265px",
    border: "1px solid #eee",
    borderRadius: "14px",
    fontSize:"15px",
    fontWeight:"700",
    lineHeight: "17px",
    color: "#fff",
    mixBlendMode: "normal",
    marginRight: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carouselTopText: {
    fontWeight: 600,
    fontSize: "42px",
    lineHeight: "100%",
    textTransform: "capitalize",
    color: "#212121",
    margin: "140px 0px 15px 0px",
  },
  carouselBottomText: {
    fontWeight: 700,
    fontSize: "52px",
    lineHeight: "100%",
    color: "#212121",
    marginRight: "25px",
  },
  searchContainer: {
    background: "#f3f8fb",
    borderTopLeftRadius:"14px",
    borderTopRightRadius:"14px",
    width: "580px",
    height: "auto ",
    padding: "0px 24px 24px 24px",
    position: "relative",
    bottom:0,
    // right:50,
  },
  topText:{
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "0.1px",
    color: "#000",
  },
  carouselActiveBtn: {
    ...commonStyles.title3,
    fontWeight:"700",
    lineHeight: "100%",
    textAlign: "center",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    color: "#FF4E00",
    height: 55,
    display: "inline-flex",
    alignItems: "center",
  },
  carouselBtn: {
    ...commonStyles.title3,
    fontWeight:"700",
    lineHeight: "100%",
    textAlign: "center",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    color: "#424242",
    height: 55,
    display: "inline-flex",
    alignItems: "center",
  },
  mLeft: {
    marginLeft: "50px",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },

  "@media (max-width: 550px)": {
    scrollable: {
      width: "100%",
      height: "52px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #D8DEE8",
      borderRadius: "8px",
      margin: "20px 0px 16px -10px",
      color: "#424242",
      ...commonStyles.subtitle3,
      paddingLeft: "40px",
      appearance: "none",
      backgroundPosition: "calc(100% - 25px) center !important",
      background: `url(${bgIcon}) no-repeat`,
      "&::-webkit-appearance": {
        display: "none",
      },
      "&::-moz-appearance": {
        display: "none",
      },
      "&::-ms-appearance": {
        display: "none",
      },
    },

    locationIcon: {
      position: "relative",
      left: "18px",
    },
    input: {
      width: "100%",
      height: "42px",
      background: "#FFFFFF",
      border: "1px solid #eee",
      borderRadius: "11.5px",
      marginLeft: "-18px",
      ...commonStyles.headline4,
      lineHeight: "14px",
      // paddingLeft: "40px",
      marginTop:"15px",
      color:"#000",
      "&::placeholder":{
        color:"#A9A7A7"
      }
    },
    searchIcon: {
      position: "relative",
      left: "15px",
      color: "lightgray",
    },
    homeTraining: {
      height: "52px",
      background: "#FFFFFF",
      border: "1px solid #D8DEE8",
      borderRadius: "8px",
      margin: "20px 0px 16px 0px",
      color: "#424242",
      ...commonStyles.subtitle3,
      paddingLeft: "30px",
    },
    inputTwo: {
      width: "328px",
      height: "52px",
      background: "#FFFFFF",
      border: "1px solid #D8DEE8",
      borderRadius: "8px",
      marginLeft: "-12px",
      ...commonStyles.subtitle3,
      lineHeight: "14px",
      "&::placeholder": {
        paddingLeft: "40px",
      },
    },
    searchBtn: {
      padding: "14px 50px",
      background: "#ff4e00",
      borderRadius: "11px",
      width: "100%",
      marginTop: "24px",
      marginBottom:"0px",
      height:"42px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "16px",
      marginLeft:"0px"
    },
    activeSearchBtn: {
      background: "#ff4e00",
    },
    searchBtnText: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
    },
    searchIcon: {
      position: "relative",
      top:"28px",
      left: "18px",
      color: "lightgray",
    },
    carouselCard: {
      maxWidth: "550px",
      height: "auto",
      width: "100%",
      backgroundColor: "#fff",
      padding: "24px 12px 0px 12px",
      background: "none",
      position:"unset",
    },
    homeTrainingBtn: {
      height: "40px",
      width: "100%",
      background: "#FFFFFF",
      border: "1px solid #eee",
      borderRadius: "11px",
      lineHeight: "12px",
      fontSize:"11px",
      fontWeight:"700",
      color: "#424242",
      mixBlendMode: "normal",
      marginTop: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mobBtn:{
      margin:"16px auto 0px auto"
    },
    homeTrainingMobBtn:{
      width:"280px !important",
      whiteSpace:"nowrap",
      marginTop:"16px",
    },
    homeTrainingActiveBtn: {
      background: "#FF4E00",
      lineHeight: "12px",
      color: "#FFFFFF",
      mixBlendMode: "normal",
      height: "40px",
      width: "100%",
      borderRadius: "11px",
      fontSize:"11px",
      fontWeight:"700",
      mixBlendMode: "normal",
      marginTop: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop:"16px"
    },
    carouselTopText: {
      whiteSpace: "nowrap",
      ...commonStyles.subtitle1,
      display:"block",
      lineHeight: "100%",
      textTransform: "capitalize",
      color: "#212121",
      padding: "0px 100px 8px 0px",
      margin: "0",
    },
    carouselBottomText: {
      ...commonStyles.title1,
      display:"block",
      lineHeight: "100%",
      color: "#212121",
      padding: "0px 50px 20px 0px",
    },
    whiteBg:{
      background:"#edf3fd",
      padding:"24px",
      borderTopLeftRadius:"14px",
      borderTopRightRadius:"14px",
      marginBottom:"12px",
    },
    carouselActiveBtn: {
      ...commonStyles.title5_new,
      textAlign: "center",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
      color: "#FF4E00",
      padding: "16px 0px",
      borderRadius: "28px",
      height: 44,
      display: "inline-flex",
      alignItems: "center",
    },
    carouselBtn: {
      ...commonStyles.title5_new,
      textAlign: "center",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
      color: "#424242",
      padding: "16px 0px",
      borderRadius: "28px",
      height: 44,
      display: "inline-flex",
      alignItems: "center",
    },
    bottomLine:{
      width:"70%",
      display:"inline-block",
      borderBottom:"2px solid #FF4E00",
      paddingBottom:"10px"
    },
    mLeft: {
      marginLeft: "40px",
    },
    homeTrainingPrimaryBtn: {
      background: "#FF4E00",
      color: "#FFFFFF",
    },
    flexTwo:{
     display: "flex",
    },
    flexColTwo:{
      flexDirection: "column",
    }
  },
  carouselBannerContainer: {
    // width: "100%",
  },
  carouselBannerDiv: {
    display:"block",
    "& img": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },
  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyEnd: {
    justifyContent: "end",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignStart:{
    alignItems: "flex-start"
  },
  flexCol: {
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  alignEnd: {
    alignItems: "end",
  },
}));

export default style;
