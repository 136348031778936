import React from "react";

import { isMobile } from "react-device-detect";
import DeskFooter from "./DeskFooter";
import MobileFooter from "./MobileFooter";

export default function Footer(){
  return(
    <>
      {
        isMobile ? <MobileFooter /> : < DeskFooter />
      }
    </>
  )
}
