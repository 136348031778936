import React from "react";
import clsx from "clsx";
import useStyles from "./styles";

import homeIcon from "../../icons/home-nav.svg";
import pnpIcon from "../../icons/newPnp.png";
import whistleIcon from "../../icons/whistle-nav.svg";
import bagIcon from "../../icons/bag-nav.svg";
import eventIcon from "../../icons/event.png";

export default function Navigation() {
  const classes = useStyles();
  return (
    <>
      <div
        className={clsx(
          classes.navBar,
          classes.flex,
          classes.alignCenter,
          classes.spaceBw
        )}
      >
        <div
          className={clsx(classes.flex, classes.flexCol, classes.alignCenter)}
        >
          <a href="/">
            <img className={clsx(classes.icon)} src={homeIcon} alt="icon" />
          </a>
          <span className={clsx(classes.activeNavText)}>Home</span>
        </div>
        <div
          className={clsx(classes.flex, classes.flexCol, classes.alignCenter)}
        >
          <a href="https://khelomore.com/sports-venues">
            <img className={clsx(classes.icon)} src={pnpIcon} alt="icon" />
          </a>
          <span className={clsx(classes.navText)}>Play</span>
        </div>
        <div
          className={clsx(classes.flex, classes.flexCol, classes.alignCenter)}
        >
          <a href="https://khelomore.com/sports-coaching/?category=at_home">
            <img className={clsx(classes.icon)} src={whistleIcon} alt="icon" />
          </a>
          <span className={clsx(classes.navText)}>Coaching</span>
        </div>
        <div
          className={clsx(classes.flex, classes.flexCol, classes.alignCenter)}
        >
          <a href="https://www.khelomore.com/events/explore-events">
            <img className={clsx(classes.icon)} src={eventIcon} alt="icon" />
          </a>
          <span className={clsx(classes.navText)}>Events</span>
        </div>
        <div
          className={clsx(classes.flex, classes.flexCol, classes.alignCenter)}
        >
          <a href="https://shop.khelomore.com/">
            <img className={clsx(classes.icon)} src={bagIcon} alt="icon" />
          </a>
          <span className={clsx(classes.navText)}>Shop</span>
        </div>
      </div>
    </>
  );
}
