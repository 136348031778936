import { React, useState } from "react";
import useStyles from "./styles";
import clsx from "clsx";

import SportsVenues from "./SearchVenues/SportsVenues";
import HomeTraining from "./SearchVenues/HomeTraining";

export default function MobileCarousel(props) {
  const classes = useStyles();
  const [isActive, setActive] = useState(true);

  const [selectedHomeTraining, setselectedHomeTraining] = useState(false);

  const homeTrainingHandler = (param) => {
    setselectedHomeTraining(param);
  };

  const sportsVenuesHandler = () => {
    setselectedHomeTraining(false);
    setActive(!isActive);
  };

  return (
    <>
      <div className={classes.carouselCard}>
        <h1>
          <span className={classes.carouselTopText}>
            Be part of the largest
          </span>
          <span className={classes.carouselBottomText}>
            Sports Ecosystem In India
          </span>
        </h1>
        <div className={classes.whiteBg}>
          <div className={clsx(classes.flex)}>
            <button
              onClick={sportsVenuesHandler}
              className={clsx(
                isActive ? classes.carouselActiveBtn : classes.carouselBtn,
                classes.flex,
                classes.flexCol,
                classes.alignStart
              )}
            >
              Venues
              <span className={isActive ? classes.bottomLine : null}></span>
            </button>
            <button
              onClick={sportsVenuesHandler}
              className={clsx(
                classes.mLeft,
                !isActive ? classes.carouselActiveBtn : classes.carouselBtn,
                classes.flex,
                classes.flexCol,
                classes.alignStart
              )}
            >
              Coaching
              <span className={!isActive ? classes.bottomLine : null}></span>
            </button>
            <a
              href="https://www.khelomore.com/events/explore-events"
              className={clsx(
                classes.mLeft,
                classes.carouselBtn,
                classes.flex,
                classes.flexCol,
                classes.alignStart
              )}
            >
              Events
            </a>
          </div>
          {isActive ? (
            <SportsVenues {...props} />
          ) : (
            <HomeTraining handler={homeTrainingHandler} />
          )}
        </div>
      </div>
    </>
  );
}
