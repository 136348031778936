import { makeStyles } from "@material-ui/core/styles";
import bgImage from "../../images/getKM.svg";
import deskBgImage from "../../images/DeskGetKM.svg";

const style = makeStyles((theme) => ({
  mobileScreens: {
    width: "572px",
    height: "381px",
  },
  getKmInnerContainer: {
    height: "382px",
    backgroundColor: "#E8F0FD",
    backgroundImage: `url(${deskBgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundPositionY: "bottom",
    border: "1px solid #CEE0FF",
    borderRadius: "8px",
    textAlign: "center",
    margin: "15px 20px",
  },
  padding: {
    padding: "36px 0px 30px 119px",
  },
  getKmHeaderTop: {
    fontWeight: 700,
    fontSize: "40px",
    lineHeight: "46px",
    color: "#000000",
  },
  getKmSubHead: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "26px",
    color: "#000000",
    margin: "10px 0px 30px 0px",
  },
  labelActive: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "13px",
    color: "#FF4E00",
  },
  fieldset: {
    border: "none",
    padding: 0,
    display: "flow-root !important",
  },
  label: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "13px",
    color: "#4A4949",
    "& > input[type=radio]": {
      display: "none",
    },
    "& > input[type=radio] + *::before": {
      content: "''",
      display: "inline-block",
      verticalAlign: "bottom",
      width: "1rem",
      height: "1rem",
      marginRight: "0.3rem",
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: "0.1rem",
      borderColor: "gray",
    },
    "& > input[type=radio]:checked + * ": {
      color: "#FF4E00",
    },
    "& > input[type=radio]:checked + *::before ": {
      background:
        "radial-gradient(#FF4E00 0%, #FF4E00, transparent 50%, transparent)",
      borderColor: "#FF4E00",
    },
  },
  contactCard: {
    position: "relative",
    width: "80%",
  },
  contactInput: {
    outline: "none",
    width: "100%",
    height: "56px",
    margin: "16px 0px 15px 0px",
    padding: "16px 0px 14px 38px",
    background: "#FFFFFF",
    border: "1px solid #D8D8D8",
    borderRadius: "28px",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "26px",
    color: "#000000",
    opacity: "0.7",
  },
  contactArrow: {
    position: "absolute",
    top: "26px",
    left: "calc(100% - 42px)",
    right: "0px",
    width: "34px",
    height: "34px",
    background: "#FF4E00",
    borderRadius: "50%",
    padding: "10px",
    cursor: "pointer",
  },
  register: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "26px",
    textAlign: "center",
    color: "#000000",
    marginBottom: "28px",
  },
  appSection: {
    width: "260px",
    height: "46px",
    background: "#000000",
    border: "1px solid #000000",
    borderRadius: "50px",
    padding: "2px",
  },
  store: {
    margin: "5px 0px 0px 11px",
    cursor: "pointer",
  },
  download: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "15px",
    color: "#FFFFFF",
  },
  appStore: {
    width: "16px",
    height: "16px",
  },
  appStoreFirst: {
    marginRight: "11px",
  },
  activeRadio: {
    marginRight: "32px",
  },
  textInitial: {
    textAlign: "initial",
  },
  "@media(min-width: 550px) and (max-width:1220px)": {
    mobileScreens: {
      display: "none",
    },
    getKmInnerContainer: {
      justifyContent: "center",
    },
  },
  "@media (max-width:550px)": {
    getKmContainer: {
      background: "#FFFFFF",
      padding: "16px",
    },
    getKmInnerContainer: {
      height: "260px",
      backgroundColor: "#E8F0FD",
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      backgroundPositionY: "bottom",
      border: "1px solid #CEE0FF",
      borderRadius: "8px",
      textAlign: "center",
      padding: "18px",
      margin: 0,
    },
    getKmHeaderTop: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "24px",
      color: "#000000",
    },
    getKmSubHead: {
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "12px",
      color: "#000000",
      margin: "8px 0px 17px 0px",
    },
    labelActive: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "12px",
      color: "#FF4E00",
    },
    fieldset: {
      border: "none",
      padding: 0,
      display: "flow-root !important",
    },
    label: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "12px",
      color: "#4A4949",
      "& > input[type=radio]": {
        display: "none",
      },
      "& > input[type=radio] + *::before": {
        content: "''",
        display: "inline-block",
        verticalAlign: "bottom",
        width: "1rem",
        height: "1rem",
        marginRight: "0.3rem",
        borderRadius: "50%",
        borderStyle: "solid",
        borderWidth: "0.1rem",
        borderColor: "gray",
      },
      "& > input[type=radio]:checked + * ": {
        color: "#FF4E00",
      },
      "& > input[type=radio]:checked + *::before ": {
        background:
          "radial-gradient(#FF4E00 0%, #FF4E00, transparent 50%, transparent)",
        borderColor: "#FF4E00",
      },
    },
    contactCard: {
      position: "relative",
      width: "60%",
      margin: "0 auto",
    },
    contactInput: {
      outline: "none",
      width: "100%",
      height: "32px",
      margin: "17px 0px 8px 0px",
      padding: "17px",
      background: "#FFFFFF",
      border: "0.579592px solid #D8D8D8",
      borderRadius: "16.2286px",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "12px",
      color: "#000000",
      opacity: "0.7",
    },
    contactArrow: {
      position: "absolute",
      top: "23px",
      left: "calc(100% - 28px)",
      width: "24px",
      height: "24px",
      background: "#FF4E00",
      borderRadius: "50%",
      padding: "5px",
      cursor: "pointer",
    },
    register: {
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      color: "#000000",
      marginBottom: "14px",
    },
    appSection: {
      width: "160px",
      margin: "0 auto",
      height: "24px",
      background: "#000000",
      border: "0.579592px solid #000000",
      borderRadius: "28px",
      padding: "0px",
    },
    store: {
      margin: "0px 0px 0px 8px",
      cursor: "pointer",
    },
    download: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "9px",
      color: "#FFFFFF",
    },
    appStore: {
      width: "8.5px",
      height: "9.3px",
    },
    appStoreFirst: {
      marginRight: "8px",
    },
    activeRadio: {
      marginRight: "32px",
    },
  },
  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyEnd: {
    justifyContent: "end",
  },
  alignCenter: {
    alignItems: "center",
  },
  flexCol: {
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  alignEnd: {
    alignItems: "end",
  },
}));

export default style;
