import { makeStyles } from "@material-ui/core";

const style = makeStyles((theme) => ({
    main: {
        position: "relative",
        // maxWidth: 1440,
        // margin: "0 auto",
        // width: "100%",
        background: "#FFF",
        padding:"0px 60px"
    },
    "@media (max-width: 550px)": {
        width: "100%",
        main:{
            padding:"0px"
        }
    }

}))

export default style;

