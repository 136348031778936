import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({

  bg: {
    background: "#FFFFFF",
    borderRadius: "8px",
    margin: "16px 20px",
    padding: 24,
  },
  positionRelative: {
    position: "relative",
  },

  arrow: {
    display: "none",
    position: "absolute",
    width: "34px",
    height: "34px",
    background: "#FFFFFF",
    border: "1px solid #424242",
    borderRadius: "4px",
    top: "43%",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  leftArrow: {
    left: 0,
  },
  rightArrow: {
    right: 0,
  },

}))

export default style;
