import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sidePanel: {
    position: "fixed",
    right: 0,
    top: 0,
    background: theme.palette.whiteColor,
    height: "100%",
    zIndex: 200,
    transform: "translate3d(100%, 0px, 0px)",
    transition: "transform 0.6s ease-out",
    willChange: "transform",
    display: "none",
  },
  sidePanelOpen: {
    display: "block",
    transform: "translate3d(0%, 0px, 0px)",
    transition: "transform 2s ease-out",
  },
}));

export default useStyles;
