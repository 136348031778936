import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useStyles from "./styles";
import MobInstaLoader from "../skeletonLoader/MobInstaLoader";

export default function MobileVenueCards({ cardData, loading }) {
  const classes = useStyles();
  const [firstRow, setFirstRow] = useState([]);
  const [secondRow, setSecondtRow] = useState([]);

  useEffect(() => {
    if (cardData?.length) {
      const tempFirstRow = cardData?.filter((obj, idx) => idx % 2 === 0);
      const tempSecondtRow = cardData?.filter((obj, idx) => idx % 2 === 1);

      setFirstRow(tempFirstRow);
      setSecondtRow(tempSecondtRow);
    }
  }, [cardData]);

  const getImageCard = (imgData) => (
    <div className={classes.relative}>
      {imgData?.tag === "Popular" ? (
        <div className={clsx(classes.popularTag)}>
          <h6>{imgData?.tag}</h6>
        </div>
      ) : imgData?.tag === "New" ? (
        <div className={clsx(classes.newTag)}>
          <h6>{imgData?.tag}</h6>
        </div>
      ) : null}
      <a href={imgData?.redirectionURL}>
        <img
          className={clsx(classes.instaImage)}
          src={imgData?.imageURL}
          alt="venueImg"
        />
        {imgData?.sportName === "Table Tennis" ? (
          <div className={clsx(classes.sportTt,classes.sportName)}>{imgData?.sportName}</div>
        ) : (
          <div className={classes.sportName}>{imgData?.sportName}</div>
        )}
      </a>
    </div>
  );

  return (
    <>
      <div>
        <div
          className={clsx(
            classes.flex,
            classes.spaceBw,
            classes.popularVenueHead
          )}
        >
          <div className={clsx(classes.flex, classes.alignCenter)}>
            <h2 className={clsx(classes.popularVenueText)}>
              Book A Sports Venue Near You
            </h2>
          </div>
          <div
            className={clsx(
              classes.viewAllBtn,
              classes.flex,
              classes.alignCenter,
              classes.justifyCenter
            )}
          >
            <a
              className={clsx(
                classes.viewAll,
                classes.flex,
                classes.alignCenter
              )}
              href="https://www.khelomore.com/sports-venues"
            >
              View all
            </a>
          </div>
        </div>
        <div className={clsx(classes.imageContainer, classes.flex)}>
          {loading ? (
            <MobInstaLoader />
          ) : (
            firstRow?.map((firstRowData, idx) => (
              <div>
                <div
                  className={clsx(
                    classes.imageCard,
                    classes.flex,
                    classes.flexCol
                  )}
                  key={firstRowData?.id}
                >
                  {getImageCard(firstRowData)}
                  {secondRow[idx] ? getImageCard(secondRow[idx]) : null}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}
