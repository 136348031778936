import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import useStyles from "./styles";
import { deskPersonalTrainingData } from "../jsonData/perssonalTrainingData";
import { mobPersonalTrainingData } from "../jsonData/perssonalTrainingData";
import { TRENDING_POPULAR_BANNER } from "../../gqlOperations/queries";


import DeskPtCards from "./DeskPtCards";
import MobilePtCards from "./MobilePtCards";

export default function PtCards() {
  const classes = useStyles();

  const {loading, error , data} = useQuery(TRENDING_POPULAR_BANNER);


  const cardData = deskPersonalTrainingData?.map((details) => details);

  return (
    <>
      <div className={classes.trainingCard}>
        {isMobile ? (
          <MobilePtCards
            mobCardData={cardData}
            loading={loading}
          />
        ) : (
          <DeskPtCards
          cardData={cardData}
          loading={loading}
          />
        )}
      </div>
    </>
  );
}
