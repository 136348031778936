import { React} from "react";


 const MobInstaLoader = () =>{
  return(
    <div>
      {

      <div style={{
        display:"flex",
        flexDirection:"column"
        }} >
        <div>
        <div style={{
        display:"flex"
        }}>
          <div>
            <div  style={{ 
              margin:"0px 20px 12px 0px ",
              borderRadius: "16px",
              width: "98px",
              height: "98px",
              backgroundColor:"#EAEAEA"
              }}>
            </div>
          </div>  
          <div>
            <div  style={{ 
              margin:"0px 20px 12px 0px ",
              borderRadius: "16px",
              width: "98px",
              height: "98px",
              backgroundColor:"#EAEAEA"
              }}>
            </div>
          </div>  
          <div>
            <div  style={{ 
              margin:"0px 20px 12px 0px ",
              borderRadius: "16px",
              width: "98px",
              height: "98px",
              backgroundColor:"#EAEAEA"
              }}>
            </div>
          </div>  
          <div>
            <div  style={{ 
              margin:"0px 20px 12px 0px ",
              borderRadius: "16px",
              width: "98px",
              height: "98px",
              backgroundColor:"#EAEAEA"
              }}>
            </div>
          </div>  
        </div>    
        </div>   

        <div>
        <div style={{
        display:"flex"
        }}>
          <div>
            <div  style={{ 
              margin:"0px 20px 12px 0px ",
              borderRadius: "16px",
              width: "98px",
              height: "98px",
              backgroundColor:"#EAEAEA"
              }}>
            </div>
          </div>  
          <div>
            <div  style={{ 
              margin:"0px 20px 12px 0px ",
              borderRadius: "16px",
              width: "98px",
              height: "98px",
              backgroundColor:"#EAEAEA"
              }}>
            </div>
          </div>  
          <div>
            <div  style={{ 
              margin:"0px 20px 12px 0px ",
              borderRadius: "16px",
              width: "98px",
              height: "98px",
              backgroundColor:"#EAEAEA"
              }}>
            </div>
          </div>  
          <div>
            <div  style={{ 
              margin:"0px 20px 12px 0px ",
              borderRadius: "16px",
              width: "98px",
              height: "98px",
              backgroundColor:"#EAEAEA"
              }}>
            </div>
          </div>  
        </div>    
        </div>          
       
      </div>               
    }
    </div>
  )
}

export default MobInstaLoader;

