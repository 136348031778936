import React from "react";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import HelpAndKMList from "../HelpAndKMList";
import useStyles from "./styles";
import KMlogo from "../../../icons/KM_LOGO.svg";
import guestUserIcon from "../../../icons/userIcon.svg";
import crossIcon from "../../../icons/black_cross_icon.svg"


function LoginHamburger({ rightDrawerClose }) {
  const classes = useStyles();

  return (
    <div className={classes.loginHamburger}>
      <div className={classes.logoContainer}>
        <div className={classes.logo}>
          <img className={classes.kmLogo} src={KMlogo} alt="KM-logo" />
        </div>
        <span onClick={() => rightDrawerClose()} className={classes.backArrow}>
          <img className={classes.crossIcon} src={crossIcon} alt="cross-icon" />
        </span>
      </div>

      {/* <div
        className={clsx(classes.df, classes.alignCenter, classes.guestUserCard)}
      >
        <Avatar classes={{ root: classes.userAvatar }} src={guestUserIcon}></Avatar>
        <div className={classes.userDetails}>
          <p className={classes.priceText}>Guest User</p>
          <p className={classes.lightColor}>Login to KheloMore</p>
        </div>
      </div> */}

      <List
        className={classes.listWrapper}
        classes={{ root: classes.muiListWrapper }}
      >
        <HelpAndKMList />
      </List>
    </div>
  );
}

export default LoginHamburger;
