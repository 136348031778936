import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import useStyles from "./styles";
import { reviewData } from "../jsonData/review";
import { TRENDING_POPULAR_BANNER } from "../../gqlOperations/queries";


import DeskReview from "./DeskReview";
import MobileReview from "./MobileReview";

export default function CustomerReview() {
  const classes = useStyles();

  const {loading, error , data} = useQuery(TRENDING_POPULAR_BANNER);


  const cardData = reviewData?.map((details) => details);

  return (
    <>
      <div className={classes.trainingCard}>
        {isMobile ? (
          <MobileReview
            cardData={cardData}
            loading={loading}
          />
        ) : (
          <DeskReview
          cardData={cardData}
          loading={loading}
          />
        )}
      </div>
    </>
  );
}
