import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../theme/common.styles";

const style = makeStyles((theme) => ({
  "@media (max-width: 550px)": {
    fav: {
      background: "#FFFFFF"
    },
    favImg:{
      width:"68px",
      height:"68px"
    },
    popularVenueHead:{
      padding: "12px 18px 10px 16px",
    },
    favHead: {
      ...commonStyles.title3,
      lineHeight: "100%",
      textTransform: "capitalize",
      color: "#212121",
    },
    favCardTop: {
      marginBottom: "12px"
    },
    hr: {
      margin: 0,
      color: "#98A0A2",
      border: "none",
      borderTop: "1px solid #EDF3FD",
    },
    cardText: {
      margin: "0px 14px 0px 13px"
    },
    cardTextHead: {
      ...commonStyles.title4,
      lineHeight: "18px",
      color: "#172B4D"
    },
    sliceText:{
      lineHeight: "18.5px",
      height: "59px",
      overflow: "hidden",
    },
    cardTextPara: {
      ...commonStyles.subtitle5,
      lineHeight: "15px",
      color: "#98A0A2"
    },
    scrollContainer: {
      overflowY: "hidden",
      padding: "18px 0px 0px 12px",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&::-moz-scrollbars": {
        display: "none",
      },
    },
    arrowIcons: {
      zIndex: 99999,
      position: "relative",
    },
    flex: {
      display: "flex"
    },
    spaceBw: {
      justifyContent: "space-between"
    },
    flexCol: {
      flexDirection: "column"
    },
    flex50: {
      flex: "0 1 50%",
    }


  }
}))

export default style; 