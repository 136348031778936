import { React} from "react";


 const MobAtHomeLoader = () =>{
  return(
    <div>
      {

      <div>
          <div  style={{
              width: "95%",
              height: "132px",
              margin:"0 auto",   
              borderRadius:"16px",
              backgroundColor:"#EAEAEA"         
            }}>           
          </div>
      </div>  
    }
    </div>
  )
}

export default MobAtHomeLoader;

