export const deskPersonalTrainingData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/web-cr-one.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching/society/khelomore-cricket-academy---thane/mumbai/348",
    firstName:"Cricket Academy",
    secondName:"-Thane"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/web-cr-two.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching/society/district-sports-club---bandra/mumbai/273",
    firstName:"Cricket Academy",
    secondName:"-Bandra"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/web-cr-three.png",
    redirectionURL: "https://shop.khelomore.com/products/sg-kashmir-willow-cricket-kit-with-helmet?variant=40846001897514",
    firstName:"Cricket",
    secondName:"Starter Kits"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/web-cr-four.png",
    redirectionURL: "https://shop.khelomore.com/collections/cricket-collection",
    firstName:"Cricket",
    secondName:"Accessories"
  },
];

export const mobPersonalTrainingData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textAwm.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching/get-a-coach/weight-nutrition-adults",
    firstName:"Nutrition And Weight",
    secondName:"Management For Adults"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textKwm.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching/get-a-coach/weight-nutrition-kids",
    firstName:"Nutrition And Weight",
    secondName:"Management For Kids"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textMed.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching/get-a-coach/meditation-mindfulness",
    firstName:"Meditation and",
    secondName:"Mindfullness"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textPt.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching/get-a-coach/physical-training",
    firstName:"Physical",
    secondName:"Training"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textPilates.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching/get-a-coach/pilates",
    firstName:"Pilates",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textYoga.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching/get-a-coach/yoga-flexibility",
    firstName:"Yoga & Flexibility",
  },
];