import React from "react";
import { isMobile } from "react-device-detect";
import { useQuery } from "@apollo/client";
import DesktopHeader from "../../Header/DesktopHeader";
import MobileHeader from "../../Header/MobileHeader";
import DeskFooter from "../DeskFooter";
import MobileFooter from "../MobileFooter";

import { CAREER } from "../../../gqlOperations/queries";

export default function Career(){
  document.title = "Job Opportunities at KheloMore";

  const {loading, error , data} = useQuery(CAREER);
  const htmlData = data?.careers?.data.map((details) => details?.attributes?.Value);
  return(
    <>
    {isMobile ?
    <div>
      <MobileHeader/>
      <div>
        <div dangerouslySetInnerHTML={{__html: htmlData}}/>
      </div>
      <MobileFooter/>
    </div>
    :
    <div>
      <DesktopHeader/>
        <div>
          <div dangerouslySetInnerHTML={{__html: htmlData}}/>
        </div>
      <DeskFooter />  
    </div>
      
  }
  </>
  )
}
