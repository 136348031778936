import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import { TRAINING_BY_COACHES } from "../../gqlOperations/queries";
import useStyles from "./styles";

import MobileTrainingVenue from "./MobileTrainingVenue";
import DeskTrainingVenue from "./DeskTrainingVenue";
import TrainingCard from "./TrainingCard";

export default function TrainingVenue() {
  const classes = useStyles();
  const scrl = useRef(null);
  const { loading, error, data } = useQuery(TRAINING_BY_COACHES);

  const [currentData, setCurrentData] = useState(data?.getTrainedAtHomes?.data);
  const [activeMode, setActiveMode] = useState();
  const modes = ["Mumbai", "Bengaluru"];

  useEffect(() => {
    setCurrentData(data?.getTrainedAtHomes?.data);
    handleTrainingData("Mumbai")
  }, [data]);

  const handleTrainingData = (mode) => {
    const filteredData = data?.getTrainedAtHomes?.data?.filter(
      (obj) =>
        obj?.attributes?.mode?.toLocaleLowerCase() === mode?.toLocaleLowerCase()
    );
    if (mode?.toLocaleLowerCase() === activeMode) {
      setActiveMode("");
      setCurrentData(data?.getTrainedAtHomes?.data);
    } else {
      setActiveMode(mode);
      setCurrentData(filteredData);
    }
  };

  const trainingData = currentData?.map((details) => details.attributes);

  return (
    <>
      <div className={classes.trainingCard}>
        {isMobile ? (
          <MobileTrainingVenue
            handleTrainingData={handleTrainingData}
            activeMode={activeMode}
            modes={modes}
          />
        ) : (
          <DeskTrainingVenue
            handleTrainingData={handleTrainingData}
            activeMode={activeMode}
            modes={modes}
          />
        )}
          <TrainingCard data={trainingData} loading={loading} />
      </div>
    </>
  );
}
