import React from "react";

import { isMobile } from "react-device-detect";
import { useQuery } from "@apollo/client";
import { BLOGS_ARTICLES } from "../../gqlOperations/queries";
import DeskBlog from "./DeskBlog";
import MobileBlog from "./MobileBlog";

export default function Blog(){

  const {
    loading,
    error,
    data,
    } = useQuery(BLOGS_ARTICLES);
    const blogData = data?.blogsAndArticles?.data?.map((singleBlog) => singleBlog?.attributes);
    
  return(
    <>
      {
        isMobile ? <MobileBlog data={blogData} loading={loading} /> : < DeskBlog data={blogData} loading={loading} />
      }
    </>
  )
}
