export const deskPromotionData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/desk-pnp-newImg.png",
    redirectionURL: "https://www.khelomore.com/sports-venues",
    cardName:"Sports Venues"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/desk-coaching-newImg.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching",
    cardName:"Coaching"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/deskPromoShop.png",
    redirectionURL: "https://shop.khelomore.com/",
    cardName:"Shop"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/event-desk-new.png",
    redirectionURL: "https://www.khelomore.com/events/explore-events",
    newTagUrl: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/new-ev-tag.png",
    cardName:"Events Near You"
  },
];

export const mobPromotionData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/mob-pnp-nImg.png",
    redirectionURL: "https://www.khelomore.com/sports-venues",
    firstName:"Sports",
    secondName:"Venues"
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/mob-coch-nImg.png",
    redirectionURL: "https://www.khelomore.com/sports-coaching",
    firstName:"Coaching",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/mobPromoShop.png",
    redirectionURL: "https://shop.khelomore.com/",
    firstName:"Shop",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/event-mob-card.png",
    redirectionURL: "https://www.khelomore.com/events/explore-events",
    newTagUrl: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/new-ev-tag.png",
    firstName:"Events",
    secondName:"Near You",
  },
];