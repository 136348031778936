import { React, useState } from "react";
import clsx from "clsx";
import useStyles from "./styles";
import BannerLoader from "../skeletonLoader/BannerLoader";

export default function DeskVenueCards({ cardData, loading }) {
  const classes = useStyles();
  return (
    <>
      <div>
        <div
          className={clsx(
            classes.flex,
            classes.spaceBw,
            classes.popularVenueHead
          )}
        >
          <div className={clsx(classes.flex, classes.alignCenter)}>
            <h2 className={clsx(classes.popularVenueText)}>
              Book A Sports Venue Near You
            </h2>
          </div>
          <div
            className={clsx(
              classes.viewAllBtn,
              classes.flex,
              classes.alignCenter,
              classes.justifyCenter
            )}
          >
            <a
              className={clsx(
                classes.viewAll,
                classes.flex,
                classes.alignCenter
              )}
              href="https://www.khelomore.com/sports-venues"
            >
              View all
            </a>
          </div>
        </div>

        <div className={clsx(classes.flex, classes.scrollContainer,classes.relative)}>
          {loading ? (
            <BannerLoader />
          ) : (
            cardData?.map((details, idx) => (
              <div className={classes.relative}>
                {details?.tag === "Popular" ? (
                  <div
                    className={clsx(
                      classes.popularTag
                    )}
                  >
                    <h6>{details?.tag}</h6>
                  </div>
                ) : details?.tag === "New"? (
                  <div
                    className={clsx(
                      classes.newTag
                    )}
                  >
                    <h6>{details?.tag}</h6>
                  </div>
                ) : null}
                <a
                  key={idx}
                  href={details.redirectionURL}
                  className={classes.homeBanner}
                >
                  <img src={details?.imageURL} alt="sport card" />
                  <div className={clsx(classes.sportTt,classes.sportName)}>{details?.sportName}</div>
                </a>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}
