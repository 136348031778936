import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import percentageIcon from "../../icons/percentage.svg";
import childIcon from "../../icons/child.svg";
import starIcon from "../../icons/star.svg";
import groupIcon from "../../icons/group.svg";
import CardLoader from "../skeletonLoader/CardLoader";


export default function TrainingCard({ data, loading}) {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.venueCard, classes.flex)} >
        {loading ? <CardLoader /> :
        data?.map((details, idx) => (
          <a key={idx} href={details.cardURL}>
            <div className={clsx(classes.trainingCardContainer)}>
              <div
                className={clsx(
                  classes.listCardTextBox,
                  classes.flex,
                  classes.spaceBw,
                  classes.alignEnd
                )}
              >
                <div className={clsx(classes.listCardTextArea,classes.flex,classes.flexCol)}>
                  <h3 className={clsx(classes.listCardHeadText)}>
                    {details?.nameOfTheCamp}
                  </h3>
                  <small className={clsx(classes.listText)}>
                    {/* { details?.academyName ? `Academy: ${details?.academyName}` :  `Coach: ${details?.coachName}`} */}
                    { details?.address}
                  </small>
                </div>
                {/* <div className={clsx(classes.flex)}>
                  <p className={clsx(classes.rating)}>
                    <img
                      className={clsx(classes.starIcon)}
                      src={starIcon}
                      alt="icon"
                    />
                    {details?.overallRating}
                  </p>
                  <small className={clsx(classes.review)}>
                    ({details?.numberOfRatings})
                  </small>
                </div> */}
              </div>
              <div className={clsx(classes.listCard, classes.relative)}>
                <div className={clsx(classes.listImgBox)}>
                  <img
                    className={clsx(classes.venueImg)}
                    src={details.imageURL}
                    alt="image"
                  />
                  <div
                    className={clsx(
                      classes.flex,
                      classes.absolute,
                      classes.spaceBw,
                      classes.favExclusive,
                      classes.alignCenter
                    )}
                  >
                    {details?.level ? (
                      <div className={clsx(classes.percentage, classes.flex,
                        classes.alignCenter)}>
                        <p
                          className={clsx(
                            classes.percentageText
                          )}
                        >
                          {details?.level}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={clsx(classes.liveBatch, classes.absolute, classes.flex, classes.alignCenter)}>
                  <p className={clsx(classes.liveText,classes.flex,classes.alignCenter)}>
                    {details?.liveBatches} BATCHES LIVE
                  </p>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </>
  );
}
