import { React} from "react";


 const CardLoader = () =>{
  return(
    <div>
      {
      <div style={{
        display:"flex",
        }} >
        <div>
          <div style={{ 
            margin:"20px 0px 5px",
            borderRadius: "16px",
            width: "370px",
            height: "20px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
          <div style={{
            display:"flex",
            width: "370px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
        </div>  
       

        <div>
          <div style={{ 
            margin:"20px 0px 5px",
            borderRadius: "16px",
            width: "370px",
            height: "20px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
          <div style={{
            display:"flex",
            width: "370px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
        </div>  
        <div>
          <div style={{ 
            margin:"20px 0px 5px",
            borderRadius: "16px",
            width: "370px",
            height: "20px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
          <div style={{
            display:"flex",
            width: "370px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
        </div>  
        <div>
          <div style={{ 
            margin:"20px 0px 5px",
            borderRadius: "16px",
            width: "370px",
            height: "20px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
          <div style={{
            display:"flex",
            width: "370px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
        </div>  
        <div>
          <div style={{ 
            margin:"20px 0px 5px",
            borderRadius: "16px",
            width: "370px",
            height: "20px",
            backgroundColor:"#EAEAEA"
          }}>
          </div>
          <div style={{
            display:"flex",
            width: "370px",
            height:"182px",
            marginRight:"20px",
            borderRadius: "16px",
            backgroundColor:"#EAEAEA"
            }}>
          </div>
        </div>  
      </div>               
    }
    </div>
  )
}

export default CardLoader;

