import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../theme/common.styles";

const useStyles = makeStyles((theme) => ({
  caption1: commonStyles.caption1,
  guestUserCard: {
    padding: "0px 24px",
    marginBottom: 22,
  },
  titleMsg: {
    paddingBottom: "20px",
  },
  loginHamburger: {},
  logoContainer: {
    color: theme.palette.whiteColor,
    ...commonStyles.subtitle2_new,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 16px 32px",
  },
  backArrow: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: 24,
    width: 24,
    cursor: "pointer",
    borderRadius: "50%",
  },
  logo: {
    display: "flex",
    alignItems: "center",
  },
  kmLogo: {
    marginRight: 8,
    lineHeight: 1,
    width: 110,
  },
  listWrapper: {
    overflowY: "auto",
    height: "calc(100vh - 210px)",
    background: theme.palette.whiteColor,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    paddingTop: 24,
  },
  priceText: {
    fontSize:"18px",
    fontWeight:"600",
    color: "#423C46",
    lineHeight: "17px",
    padding: "4px 0px",
  },
  headline3: commonStyles.headline3,
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  lightColor: {
    ...commonStyles.title5,
    color: "#423C46",
    lineHeight: "16px",
  },
  userDetails: {
    paddingLeft: "16px",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "100%",
    color: theme.palette.whiteColor,
  },
  userAvatar: {
    height: 64,
    width: 64,
  },
  df: {
    display: "flex",
  },
  alignCenter: {
    alignItems: "center",
    paddingBottom: 16,
  },
  alignStart: {
    alignItems: "flex-start",
    paddingBottom: 16,
  },
}));

export default useStyles;
