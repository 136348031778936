export const deskVenueCardData = [
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textFt.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=football",
    tag:"Popular",
    sportName:"Football",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textCr.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=cricket",
    sportName:"Cricket",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textBdm.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=badminton",
    sportName:"Badminton",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textSw.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/bengaluru?sports=swimming",
    tag:"New",
    sportName:"Swimming",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textTn.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/mumbai?sports=tennis",
    sportName:"Tennis",
  },
  {
    imageURL: "https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/non-textTT.png",
    redirectionURL: "https://www.khelomore.com/sports-venues/bengaluru?sports=table_tennis",
    sportName:"Table Tennis",
  },
];