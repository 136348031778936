import { React} from "react";


 const BannerLoader = () =>{
  return(
    <div>
      {

      <div style={{
        display:"flex",
        }} >
       
          <div style={{
            marginRight:"20px",
            borderRadius: "16px",
            width: "348px",
            height:"146px",
            background:"#EAEAEA"
            }}>
          </div>
       

          <div style={{
            marginRight:"20px",
            borderRadius: "16px",
            width: "348px",
            height:"146px",
            background:"#EAEAEA"
            }}>
          </div>
          <div style={{
            marginRight:"20px",
            borderRadius: "16px",
            width: "348px",
            height:"146px",
            background:"#EAEAEA"
            }}>
          </div>
          <div style={{
            marginRight:"20px",
            borderRadius: "16px",
            width: "348px",
            height:"146px",
            background:"#EAEAEA"
            }}>
          </div>
          <div style={{
            marginRight:"20px",
            borderRadius: "16px",
            width: "348px",
            height:"146px",
            background:"#EAEAEA"
            }}>
          </div>
      </div>               
    }
    </div>
  )
}

export default BannerLoader;

