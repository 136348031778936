import React from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import useStyles from "./styles";
import Favourites from "./DesktopPlayerFav";
import ListYourVenue from "./DesktopListVenue";
import {FAVOURITES} from "../../gqlOperations/queries";






const FavListVenue = () => {
  const classes = useStyles();
  const {loading, error , data} = useQuery(FAVOURITES);

  const favData = data?.playersFavourites?.data.map((details) => details.attributes);

  return (
    <>
    <div className={clsx(classes.flex,classes.spaceBw,classes.commonMargin)}>
      <Favourites data={favData} loading={loading} />
      <ListYourVenue/>
    </div>
    </>
  );
};

export default FavListVenue;
