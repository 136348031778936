import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff823b",
      main: "#ff4e00",
      dark: "#c30500",
      contrastText: "#ffffff",
      error: "#ff0000f0",
    },
    secondary: {
      light: "#6ed4ff",
      main: "#19a3ff",
      dark: "#0075cb",
      contrastText: "#ffffff",
    },
    whiteColor: "#ffffff",
    blackColor: "#212121",
    baseColor: "#424242",
    greyColor: "#757575",
    lightGrey: "#bdbdbd",
    whiteGrey: "#eeeeee",
    leaderboardBackground: "#030303",
    splitPaymentConfirmation: "#edf5f0",
    splitPaymentReserve: "#fefaf0",
    splitPaymentGreenFont: "#007e33",
    splitPaymentReserveHeaderFont: "#ff823b",
  },
  status: {
    error: "#cc0000",
    success: "#007e33",
    info: "#ffbb33",
    splitPaymentBanner: "#fef8ec",
  },
  typography: {
    fontFamily: "'Rajdhani', sans-serif",
  },
  overrides: {
    MuiFilledInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #898585a1",
        },
        "&:after": {
          borderBottom: "1px solid #ff0000f0",
        },
        "&:hover:before": {
          borderBottom: "1px solid #898585a1",
        },
      },
      root: {
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        "&$disabled": {
          backgroundColor: "#eeeeee",
          color: "#757575",
        },
      },
    },
    MuiInputBase: {
      input: {
        height: ".7em",
        fontSize: "14px",
        "&:placeholder": {
          fontSize: "14px",
        },
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          display: "none",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#00000059",
        fontSize: "12px",
        "&.Mui-focused": {
          color: "#00000059",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#ff0000f0",
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
    },
    PrivateTabIndicator: {},
    MuiDrawer: {
      paper: {
        maxWidth: 550,
      },
    },
    MuiDialog: {
      paper: {
        margin: 16,
        borderRadius: 28,
        background: "#EFF6FF",
        color: "#64748B",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          background: "none",
          backgroundColor: "none",
        },
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "contain",
      },
    },
    MuiPickersBasePicker: {
      container: {
        alignItems: "center",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#007e33",
        "&:hover": {
          backgroundColor: "#007e33",
        },
      },
    },
  },
});

export default theme;
