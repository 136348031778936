import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import right_Arrow from "../../icons/desRightArrow.svg";
import BlogLoader from "../skeletonLoader/BlogLoader";

export default function DeskBlog({ data, loading }) {
  const classes = useStyles();
  return (
    <>
      <div className={clsx(classes.blogContainer)}>
        <div
          className={clsx(
            classes.flex,
            classes.spaceBw,
            classes.popularVenueHead,
            classes.alignCenter
          )}
        >
          <h2 className={clsx(classes.popularVenueText)}>Blogs and Articles</h2>
          <div
            className={clsx(
              classes.viewAllBtn,
              classes.flex,
              classes.alignCenter,
              classes.justifyCenter
            )}
          >
            <a
              className={clsx(
                classes.viewAll,
                classes.flex,
                classes.alignCenter
              )}
              href="https://blog.khelomore.com/"
            >
              View all{" "}
            </a>
          </div>
        </div>
        <div className={clsx(classes.flex, classes.blogCard)}>
          {loading ? (
            <BlogLoader />
          ) : (
            // data?.map((details,idx) =>(
            <div className={classes.flex}>
              <a
                className={clsx(classes.blogImageCard)}
                target="blank"
                href="https://blog.khelomore.com/prestige-shanti-niketan-psn-run-for-a-cause-with-khelomore/"
              >
                <div className={classes.linearBox}></div>
                <img
                  className={clsx(classes.blogImage)}
                  src="https://blog.khelomore.com/wp-content/uploads/2024/02/psn-1-1024x445.png"
                  alt="image"
                />
                <div className={classes.flex}>
                  <div className={classes.blogNum}>01</div>
                  <div>
                    <div className={classes.publishDate}>
                      21st Feb 2024 | 2 mins read
                    </div>
                    <h2 className={clsx(classes.blogText)}>
                      Prestige Shanti Niketan: <br />
                      ‘PSN Run for a Cause’...
                    </h2>
                  </div>
                </div>
              </a>
              <a
                className={clsx(classes.blogImageCard)}
                target="blank"
                href="https://blog.khelomore.com/the-role-of-coaches-in-youth-sports-how-they-can-impact-your-childs-development/"
              >
                <div className={classes.linearBox}></div>
                <img
                  className={clsx(classes.blogImage)}
                  src="https://blog.khelomore.com/wp-content/uploads/2024/02/new-two-1024x440.png"
                  alt="image"
                />
                <div className={classes.flex}>
                  <div className={classes.blogNumTwo}>02</div>
                  <div>
                    <div className={classes.publishDateTwo}>
                      16th Feb 2024 | 4 mins read
                    </div>
                    <h2 className={clsx(classes.blogText)}>
                      The Role of Coaches in Youth Sports: How They Can Impact...
                    </h2>
                  </div>
                </div>
              </a>
              <a
                className={clsx(classes.blogImageCard)}
                target="blank"
                href="https://blog.khelomore.com/ispl-indian-street-premier-league-and-all-you-need-to-know-about-it/"
              >
                <div className={classes.linearBox}></div>
                <img
                  className={clsx(classes.blogImage)}
                  src="https://blog.khelomore.com/wp-content/uploads/2024/02/ispl-cover-1024x347.png"
                  alt="image"
                />
                <div className={classes.flex}>
                  <div className={classes.blogNum}>03</div>
                  <div>
                    <div className={classes.publishDate}>
                      12th Feb 2024 | 5 mins read
                    </div>
                    <h2 className={clsx(classes.blogText)}>
                      ISPL (Indian Street Premier League) And All You Need To...
                    </h2>
                  </div>
                </div>
              </a>
              <a
                className={clsx(classes.blogImageCard)}
                target="blank"
                href="https://blog.khelomore.com/promoting-physical-sports-and-fostering-a-positive-work-culture/"
              >
                <div className={classes.linearBox}></div>
                <img
                  className={clsx(classes.blogImage)}
                  src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/deskFourBl.png"
                  alt="image"
                />
                <div className={classes.flex}>
                  <div className={classes.blogNum}>04</div>
                  <div>
                    <div className={classes.publishDate}>
                      01st Aug 2023 | 5 mins read
                    </div>
                    <h2 className={clsx(classes.blogText)}>
                      Promoting Physical Sports and <br /> Fostering a Positive
                      Work Culture
                    </h2>
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
