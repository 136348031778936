import { React } from "react";

 const AtHomeLoader = () =>{
  return(
    <div>
      {

      <div style={{
        backgroundColor: "#EAEAEA", 
        borderRadius:"16px"             
        }} >
          <div style={{
             width: "100%",
             height: "200px"
          }}>
          </div>
      </div>  
    }
    </div>
  )
}

export default AtHomeLoader;

