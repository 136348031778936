import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({

    margin:{
        margin:"15px 20px"

    },
    homeBannerCard:{
        width:"100%",
    },
    "@media (max-width: 550px)":{
         homeBannerCard:{
            maxWidth: "550px",
        },
        homeBanner:{
            cursor: "pointer",
            width: "100%",
            display: "block",
            padding:"0px 16px 12px 16px"
        },
       
       }

}))

export default style;



