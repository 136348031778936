
import React, {useState} from "react";
import { Helmet } from "react-helmet";

function SeoManager() {
  const state = {
    pageTitle: 'Search Sports Coaches and Academies | KheloMore',
    socialTitle: 'Search Sports Coaches and Academies | KheloMore',
    socialDescription: 'With a digital platform of Khelo More, search for more than 2500+ sports coaches and academies near you. Book online hassle free and start playing',
    description: 'With a digital platform of Khelo More, search for more than 2500+ sports coaches and academies near you. Book online hassle free and start playing',
    keyWords: 'sports coaches in India, sports academies in India, sports coaching in india, sports classes in india',
    h1: 'Search Sports Coaches and Academies',
    h2: '',
    image: 'https://s3.ap-south-1.amazonaws.com/khelomore-images/home/HomeOg.jpg'
  };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={window?.location?.href} />
      <meta name="description" content={state.description} />
      <meta name="keywords" content={state.keyWords} />
      <meta property="og:title" content={state.socialTitle} />
      <meta property="og:description" content={state.socialDescription} />
      <meta property="og:image" content={state.image} />
      <meta property="og:image:url" content={state.image} />
      <meta property="og:url" content={window?.location?.pathname} />
      <meta name="twitter:card" content="summary_large_image" />


      {/* ----------------OGCard Section ------------------------- */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content='Khelomore' />
      <meta property="og:site_name" content="Khelomore" />
      <meta property="og:locale" content="En" />
     
    </Helmet>
  );
}
export default SeoManager;