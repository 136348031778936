import React from "react";

import { isMobile } from "react-device-detect";
import DeskRegisterBanner from "./DeskRsBanner";
import MobileRegisterBanner from "./MobileRsBanner";

export default function RegisterBanner(){
  return(
    <>
      {
        isMobile ? <MobileRegisterBanner /> : < DeskRegisterBanner />
      }
    </>
  )
}
