import React from "react";
import { useQuery } from "@apollo/client";
import { DESK_BANNER } from "../../gqlOperations/queries";
import useStyles from "./styles";
import AtHomeLoader from "../skeletonLoader/AtHomeLoader";



export default function DeskAppBanner(){
    const classes = useStyles();
  const {loading, error , data} = useQuery(DESK_BANNER);
    const imgData = data?.deskBanners.data.map((detail,idx)=> detail.attributes.imageURL);
    return(
        <>
        <div className={classes.margin}>
          { loading ? <AtHomeLoader /> :
            <a href="https://linktr.ee/khelo_more">
              <img className={classes.homeBannerCard} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/newDeskAppBanner.png" alt="home-banner" />
            </a>
          }  
        </div>
        </>
    )
}