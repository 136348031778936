import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useStyles from "./styles";
import KM_Icon from "../../icons/KM_LOGO.svg";
import apple_store from "../../icons/apple_store.svg";
import g_playStore from "../../icons/g_play.svg";
import regsiterVenueIcon from "../../images/register-venue.svg";
import hostEventIcon from "../../images/host-event.svg";

export default function DesktopHeader() {
  const classes = useStyles();

  const ctaData = [
    {
      ctaText:
        "HOST YOUR EVENT",
      linkURL:
        "https://www.khelomore.com/events/explore-events/list-events-tournaments",
      ctaIcon: hostEventIcon
    },
    {
      ctaText:
        "REGISTER YOUR VENUE",
      linkURL:
        "https://docs.google.com/forms/d/e/1FAIpQLSeHRjh5iIz-zS-iCeB1S8kPuosccr6MWV0ByZx4IAJ3nCfOVg/viewform",
      ctaIcon: regsiterVenueIcon
    },
  ];

  return (
    <>
      <header className={clsx(classes.header)}>
        <div className={clsx(classes.flex, classes.alignCenter)}>
          <Link to="/">
            <img className={classes.kmLogo} src={KM_Icon} alt="KM-logo" />
          </Link>
        </div>
        <div
          className={clsx(classes.headBox, classes.flex, classes.alignCenter,classes.justifyEnd)}
        >
          {/* <div className={clsx(classes.flex, classes.storeBox)}>
						<a className={clsx(classes.appleStore)} href="https://apps.apple.com/in/app/khelomore-book-sports-venues/id1536558207"> <img src={apple_store} alt="apple-store" /> </a>
						<a className={clsx(classes.googleStore, classes.appleStore)} href="https://play.google.com/store/apps/details?id=com.khelomore.pnp&hl=en_IN&gl=US"> <img src={g_playStore} alt="googlePlay-store" /> </a>
					</div> */}
          <h1>
            <a
              href="https://khelomore.com/sports-venues"
              className={clsx(classes.venue, classes.marginLeft)}
            >
              SPORTS VENUES
            </a>
          </h1>
          <a
            href="https://khelomore.com/sports-coaching/?category=at_home"
            className={clsx(classes.venue, classes.marginLeft)}
          >
            COACHING
          </a>
          <a
            href="https://www.khelomore.com/events/explore-events"
            className={clsx(classes.venue, classes.marginLeft)}
          >
            EVENTS
          </a>
          <a
            href="https://shop.khelomore.com/"
            className={clsx(classes.venue, classes.marginLeft)}
          >
            SHOP
          </a>
          {/* <a
            href="https://www.khelomore.com/sports-coaching/one-on-one-personal-training"
            className={clsx(classes.venue, classes.marginLeft)}
          >
            <span>PERSONAL TRAINING</span>
            <span className={clsx(classes.newTag)}>New</span>
          </a> */}
          <div className={classes.carouselArea}>
            <Carousel
              autoPlay
              infiniteLoop
              showIndicators={ctaData?.length > 1}
              showStatus={false}
              showThumbs={false}
              interval={3000}
              showArrows={false}
            >
              {ctaData?.map((data, idx) => (
                <a
                  href={data?.linkURL}
                  className={clsx(classes.venue, classes.registerCTA,classes.flex,classes.alignCenter)}
                  key={idx}
                >
                  <div>
                    <img className={classes.ctaIcon} src={data?.ctaIcon} alt="cta-icon" />
                  </div>
                  <div>
                    <span className={classes.ctaText}>{data?.ctaText}</span>
                  </div>
                </a>
              ))}
            </Carousel>
          </div>

          {/* <div className={clsx(classes.flex, classes.textBox)}>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeHRjh5iIz-zS-iCeB1S8kPuosccr6MWV0ByZx4IAJ3nCfOVg/viewform"
              className={clsx(classes.venue, classes.registerCTA)}
            >
              Register Your Venue
            </a>
          </div> */}
        </div>
      </header>
    </>
  );
}
