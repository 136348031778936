import React from "react";

let backDropDefault = {
  position: "fixed",
  left: 0,
  top: 0,
  height: "100%",
  width: "100%",
  maxWidth: 550,
  background: "rgba(0,0,0, 0.5)",
  zIndex: 110,
  opacity: 0,
  transition: "opacity 0.2s ease 0s, transform 0s ease 0.3s",
  transform: "translate3d(100%, 0px, 0px)",
};

let backDropOpen = {
  position: "fixed",
  left: 0,
  top: 0,
  height: "100%",
  width: "100%",
  maxWidth: 550,
  background: "rgba(0,0,0, 0.5)",
  zIndex: 110,
  opacity: 1,
  transition: "opacity 0.2s ease 0s",
};

function Backdrop(props) {
  const { closePanel, isSidePanelOpen } = props;

  return (
    <div
      style={isSidePanelOpen ? backDropOpen : backDropDefault}
      onClick={closePanel}
    />
  );
}

export default Backdrop;
