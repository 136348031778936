import React from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";

import useStyles from "./styles";

import { FAVOURITES } from "../gqlOperations/queries";
import { VENUE_LISTING } from "../gqlOperations/queries";

import Headers from "./Header";
import Carousels from "./HeroSection";
import Favourites from "./Favourites/Favourites";
import FavListVenue from "./DesktopFavs&ListVenue";
import TrendingBanners from "./PopularCard";
import VenueCards from "./NewVenueCards";
import PtCards from "./PersonalTraining";
import CustomerReview from "./CustomerReview";
import KMWorkBanner from "./KmWork";
import RegisterBanner from "./RegisterSociety";
import KmGallery from "./KmGallery";
import OpenAppCard from "./OpenApp/OpenAppCard";
import PopularVenues from "./PopularVenues";
import ListYourVenue from "./ListYourVenue/ListYourVenue";
import Learn from "./LearnPlay";
import RenderShopCard from "./ShopOnline";
import AppBanners from "./AppBanner";
import TrainingVenue from "./HomeTraining";
import GetKMApp from "./GetKMApp";
import InstaFeed from "./InstaFeed";
import Blog from "./Blog";
import Footer from "./Footer";
import Navigation from "./Navigation/Navigation";

export default function Main(props) {
  const classes = useStyles();

  const {
    loading: favLoading,
    error: favError,
    data: favData,
  } = useQuery(FAVOURITES);
  const {
    loading: venuelistLoading,
    error: venuelistError,
    data: venuelistData,
  } = useQuery(VENUE_LISTING);

  const favouritesData = favData?.playersFavourites?.data.map(
    (details) => details.attributes
  );
  const listData = venuelistData?.listYourVenues?.data.map(
    (details) => details.attributes
  );

  return (
    <div>
      <div className={classes.main}>
        <Headers />
        <Carousels {...props} />
        <TrendingBanners />
        <TrainingVenue />
        <AppBanners />
        <VenueCards />
        <PtCards />
        <CustomerReview />
        {/* <KMWorkBanner /> */}
        {/* {!isMobile && <PopularVenues />} */}
        {/* {!isMobile && <FavListVenue />} */}
        {/* {!isMobile && <Learn />} */}
        {/* {isMobile && <Favourites data={favouritesData} loading={favLoading} />} */}
        {/* {isMobile && <PopularVenues />} */}
        {/* {isMobile && (
          <ListYourVenue data={listData} loading={venuelistLoading} />
        )} */}
        {/* {isMobile && <Learn />} */}
        {/* <RenderShopCard /> */}
        {/* <GetKMApp /> */}
        {/* <InstaFeed /> */}
        <Blog />
        <KmGallery />
        <RegisterBanner />
      </div>
      <Footer />
      {isMobile && <OpenAppCard />}
      {isMobile && <Navigation />}
    </div>
  );
}
