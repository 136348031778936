import React from "react";
import { useQuery } from "@apollo/client";
import { DESK_BANNER } from "../../gqlOperations/queries";
import clsx from "clsx";
import useStyles from "./styles";
import AtHomeLoader from "../skeletonLoader/AtHomeLoader";




export default function DeskRegisterBanner(){
    const classes = useStyles();
  const {loading, error , data} = useQuery(DESK_BANNER);
    const imgData = data?.deskBanners.data.map((detail,idx)=> detail.attributes.imageURL);
    return(
        <>
        <div className={classes.margin}>
          { loading ? <AtHomeLoader /> :(
            <div className={clsx(classes.flex,classes.spaceBw,classes.homeBannerCard)}>
              <a className={classes.w49} target="blank" href="https://www.khelomore.com/sports-coaching/society-sports-coaching">
                <img className={classes.homeBannerCard} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/rs-desk-banner.svg" alt="register banner" />
              </a>
              <a className={classes.w49} target="blank" href="https://www.khelomore.com/events/explore-events/list-events-tournaments">
                <img className={classes.homeBannerCard} src="https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/event-desk-banner.svg" alt="event banner" />
              </a>
            </div>
          ) 
          }  
        </div>
        </>
    )
}