// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import useStyles from "../styles";
// import clsx from "clsx";

// import search_Icon from "../../../icons/search_Icon.svg";

// export default function SportsVenues(props) {
//   const classes = useStyles();
//   const [searchText, setSearchText] = useState("");

//   const handleSearchText = (event) => {
//     setSearchText(event?.target?.value);
//   };

//   return (
//     <>
//       <div className={clsx(classes.flex, classes.flexCol)}>
//         <div className={clsx(classes.flex, classes.serachInput)}>
//           <img
//             className={classes.searchIcon}
//             src={search_Icon}
//             alt="search_Icon"
//           />
//           <input
//             className={classes.input}
//             value={searchText}
//             onChange={handleSearchText}
//             placeholder="Search for sports, venue or locality"
//           />
//         </div>
//         <div>
//             <Link
//               className={clsx(classes.searchBtnText, {
//                 [classes.activeSearchBtnText]: searchText?.length,
//               })}
//               to={{
//                 pathname: "/sports-venues/search",
//                 state: { searchText },
//               }}
//             >
//                <button
//                disabled= {searchText?.length<1}
//                className={clsx(classes.searchBtn, {
//               [classes.activeSearchBtn]: searchText?.length,
//             })}
//           >
//               SEARCH NOW
//           </button>
//             </Link>
//         </div>
//       </div>
//     </>
//   );
// }


import React, { useState } from "react";
import { Link } from "react-router-dom";
import useStyles from "../styles";
import clsx from "clsx";

import search_Icon from "../../../icons/search_Icon.svg";

export default function SportsVenues(props) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");

  const handleSearchText = (event) => {
    setSearchText(event?.target?.value);
  };

  return (
    <>
      <div className={clsx(classes.flex,classes.flexColTwo)}>
        <div className={clsx(classes.flex, classes.serachInput)}>
          <img
            className={classes.searchIcon}
            src={search_Icon}
            alt="search_Icon"
          />
          <input
            className={classes.input}
            value={searchText}
            onChange={handleSearchText}
            placeholder="Search for sports, venues or locality"
          />
        </div>
        <div>
            <a
              className={clsx(classes.searchBtnText, {
                [classes.activeSearchBtnText]: searchText?.length,
              })}
              href={`https://www.khelomore.com/sports-venues/search?${searchText}`}
            >
               <button
               disabled= {searchText?.length<1}
               className={clsx(classes.searchBtn, {
              [classes.activeSearchBtn]: searchText?.length,
            })}
          >
              SEARCH NOW
          </button>
            </a>
        </div>
      </div>
    </>
  );
}
