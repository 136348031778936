import React from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import DeskPopularCard from "./DeskPopularCard";
import MobilePopularCard from "./MobilePopularCard";
import { TRENDING_POPULAR_BANNER } from "../../gqlOperations/queries";
import { deskPromotionData } from "../jsonData/promotionData";
import { mobPromotionData } from "../jsonData/promotionData";


export default function TrendingBanners(){

  const {loading, error , data} = useQuery(TRENDING_POPULAR_BANNER);

  const bannerData = data?.trendingAndPopulars?.data.map((details) => details.attributes);
  const deskPromoData = deskPromotionData?.map((promoData) => promoData)  
  const mobPromoData = mobPromotionData?.map((promoData) => promoData)  

  return(
    <>
      {
        isMobile ? <MobilePopularCard data={mobPromoData} loading={loading} /> : < DeskPopularCard data={deskPromoData} loading={loading} />
      }
    </>
  )
}
