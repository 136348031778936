import React, { useRef } from "react";
import clsx from "clsx";
import SectionWithScrollButtons from "../Common/SectionWithScrollButtons";
import BannerLoader from "../skeletonLoader/BannerLoader";
import useStyles from "./styles";

export default function DeskPopularCard({ data , loading}) {
  const classes = useStyles();
  const scrl = useRef(null);

  return (
    <>
    {/* <SectionWithScrollButtons
      isBg={true}
      onClickRight={() => {
        scrl.current.scrollLeft += 250;
      }}
      onClickLeft={() => {
        scrl.current.scrollLeft -= 250;
      }}
    > */}
      <div
        className={clsx(classes.flex, classes.scrollContainer,classes.spaceBw,classes.relative)}
        id="content"
        ref={scrl}
      >
     {loading ? <BannerLoader /> :
        data?.map((details, idx) => (
          <a key={idx} href={details.redirectionURL} className={clsx(classes.homeBanner,classes.relative)}>
            {details?.newTagUrl && <div><img className={clsx(classes.newTag,classes.absolute)} src={details?.newTagUrl} alt="new-tag" /></div>}
            <img className={classes.bannerImg} src={details.imageURL} alt="ad-banner" />
            <div className={classes.cardName}>
              {details.cardName}
            </div>
          </a>
        ))
      } 
      </div>
    {/* </SectionWithScrollButtons> */}
    </>
   
    
  );
}
