import React from "react";
import { isMobile } from "react-device-detect";
import { useQuery } from "@apollo/client";
import DesktopHeader from "../../Header/DesktopHeader";
import MobileHeader from "../../Header/MobileHeader";
import DeskFooter from "../DeskFooter";
import MobileFooter from "../MobileFooter";

import { FAQ } from "../../../gqlOperations/queries";

export default function FAQs(){
  document.title = "FAQs | KheloMore";

  const {loading, error , data} = useQuery(FAQ);
  const htmlData = data?.faqs?.data.map((details) => details?.attributes?.Value);
  return(
    <>
    {
      isMobile ? 
      <div>
        <MobileHeader/>
          <div dangerouslySetInnerHTML={{__html: htmlData}}/>
        <MobileFooter/>
      </div>
      :
      <div>
          <DesktopHeader/>
            <div>
              <div dangerouslySetInnerHTML={{__html: htmlData}}/>
            </div>
          <DeskFooter />   
      </div>
    }
        
    </>
  )
}
