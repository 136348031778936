import React from "react";
import { isMobile } from "react-device-detect";
import { useQuery } from "@apollo/client";
import DesktopHeader from "../../Header/DesktopHeader";
import MobileHeader from "../../Header/MobileHeader";
import DeskFooter from "../DeskFooter";
import MobileFooter from "../MobileFooter";

import { PRICING_AND_REFUND } from "../../../gqlOperations/queries";

export default function PricingAndRefund(){
  document.title = "Pricing Policy | KheloMore";
  const {loading, error , data} = useQuery(PRICING_AND_REFUND);
  const htmlData = data?.pricingAndRefunds?.data.map((details) => details?.attributes?.Value);
  return(
    <>
    {isMobile ? 
    <div>
      <MobileHeader/>
      <div>
        <div dangerouslySetInnerHTML={{__html: htmlData}}/>
      </div>
      {/* <MobileFooter />  */}
    </div>
    :
    <div>
        <DesktopHeader/>
          <div>
            <div dangerouslySetInnerHTML={{__html: htmlData}}/>
          </div>
        <DeskFooter />   
    </div>
    }   
    </>
  )
}
