import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({

    margin:{
        margin:"15px 0px 30px 24px"

    },
    flex:{
        display:"flex"
    },
    spaceBw:{
        justifyContent:"space-between"
    },
    w49:{
        width:"49%",
    },
    homeBannerCard:{
        width:"100%",
    },

    "@media (max-width: 550px)":{
         homeBannerCard:{
            maxWidth: "550px",
        },
        homeBanner:{
            cursor: "pointer",
            width: "100%",
            display: "block",
            padding:"12px"
        },
        carouselBannerDiv: {
            display:"block",
            padding:"12px 16px",
            "& img": {
              display: "block",
              width: "100%",
              height: "100%",
            },
        },
       }

}))

export default style;



