import React from "react";
import useStyles from "./styles";
import clsx from "clsx";

function SidePanel(props) {
  const classes = useStyles();
  const { isSidePanelOpen, children } = props;

  return (
    <div
      className={clsx(classes.sidePanel, {
        [classes.sidePanelOpen]: isSidePanelOpen,
      })}
    >
      {children}
    </div>
  );
}

export default SidePanel;
