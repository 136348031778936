import {React, useState} from "react";
import {Link} from "react-router-dom";
import useStyles from "../styles";
import clsx from "clsx";



export default function HomeTraining({handler}){

  const classes = useStyles();

  const [activeBtn, setActiveBtn] = useState();

  const setActiveBtnIdx = (idx) => setActiveBtn(idx);


  const items = [{
    title: "COACHING IN YOUR SOCIETIES/ACADEMIES",
    url: "https://www.khelomore.com/sports-coaching/?category=at_home",
    }
  ];

  return(
    <>
      <div className={clsx(classes.flex, classes.alignCenter,classes.flexColTwo)}>
        {items ?.map((item,idx) =>(
          <a
            href={item?.url}
            key={item?.title} 
            onClick={() =>{setActiveBtnIdx(idx); handler(true)}} 
            style={{width:"400px"}}
            className={clsx( activeBtn === idx ? classes.homeTrainingActiveBtn: classes.homeTrainingBtn,classes.homeTrainingMobBtn,classes.deskBtn,classes.mobBtn)}
          >{item?.title}</a>
        ))}
      </div>
    </>
  )
}