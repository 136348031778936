import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  popularVenueHead: {
    padding: "30px 24px",
  },
  popularVenueText: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    textTransform: "capitalize",
    letterSpacing: "0.105882px",
    color: "#212121",
  },
  hr: {
    margin: 0,
    color: "#98A0A2",
    border: "none",
    borderTop: "1px solid #EDF3FD",
  },
  instaContainer: {
    background: "#FFFFFF",
    margin: "15px  20px",
    borderRadius: "8px",
  },
  follow: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#47A9FF",
    textTransform: "Capitalize",
    height: "40px",
    background: "rgba(71, 169, 255, 0.1)",
    borderRadius: "12px",
    padding: "8px",
  },
  instaIcon: {
    width: "29px",
    height: "29px",
    marginRight: "14px",
  },
  instaImage: {
    width: "180px",
    height: "180px",
    borderRadius: "4px",
    marginRight: "15px",
  },
  imageContainer: {
    padding: "0px 0px 24px 24px",
    // width:"calc(100% - 32px)",
    overflowY: "hidden",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  "@media (max-width:550px)": {
    popularVenueHead: {
      padding: "12px 18px 10px 16px",
    },
    popularVenueText: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "16px",
      textTransform: "capitalize",
      color: "#212121",
    },
    hr: {
      margin: 0,
      color: "#98A0A2",
      border: "none",
      borderTop: "1px solid #EDF3FD",
    },
    instaContainer: {
      background: "#FFFFFF",
      margin: "8px 0px",
    },
    follow: {
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "14px",
      color: "#47A9FF",
      textTransform: "none",
      width: "auto",
      height: "auto",
      background: "none",
      borderRadius: "none",
      padding: 0,
    },
    instaIcon: {
      width: "12px",
      height: "12px",
      marginRight: "8px",
    },
    instaImage: {
      width: "98px",
      height: "98px",
      borderRadius: "4px",
      margin: "0px 16px 16px 0px",
    },
    imageContainer: {
      padding: "16px 0px 0px 16px",
      overflowY: "hidden",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&::-moz-scrollbars": {
        display: "none",
      },
    },
  },
  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyEnd: {
    justifyContent: "end",
  },
  alignCenter: {
    alignItems: "center",
  },
  flexCol: {
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  alignEnd: {
    alignItems: "end",
  },
}));

export default style;
