import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import useStyles from "./styles";
import { deskKmGalleryData } from "../jsonData/kmGallery";
import { TRENDING_POPULAR_BANNER } from "../../gqlOperations/queries";


import DeskKmGallery from "./DeskKmGallery";
import MobileKmGallery from "./MobileKmGallery";

export default function KmGallery() {
  const classes = useStyles();

  const {loading, error , data} = useQuery(TRENDING_POPULAR_BANNER);


  const cardData = deskKmGalleryData?.map((details) => details);

  return (
    <>
      <div className={classes.trainingCard}>
        {isMobile ? (
          <MobileKmGallery
            cardData={cardData}
            loading={loading}
          />
        ) : (
          <DeskKmGallery
          cardData={cardData}
          loading={loading}
          />
        )}
      </div>
    </>
  );
}
