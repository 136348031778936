import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  scrollContainer: {
    overflowY: "hidden",
    whiteSpace: "nowrap",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    padding:"24px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  homeBanner: {
    "& img": {
      cursor: "pointer",
      width: "340px",
      height: 358,
      paddingRight: 16,
    },
    "&:last-child": {
      "& img": {
        paddingRight: 0,
      },
    },
  },
  cardName:{
    textAlign:"center",
    color: "#212121",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "110%",
    letterSpacing: "0.5px",
  },
	kmGallery:{
		color: "#212121",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "110%",
    letterSpacing: "0.1px",
		paddingLeft:"42px"
	},
  "@media (max-width: 550px)": {
    homeBannerCard: {
      maxWidth: "550px",
      "& a": {
        width: "100%",
      },
      "& img": {
        width: "100%",
      },
    },
    scrollContainer:{
      padding:"16px"
    },
    kmGallery:{
      paddingLeft:"16px",
      fontSize:"15px",
      fontWeight:"600",
      letterSpacing:"0.1px",
      marginTop:"16px",
    },
    homeBanner: {
      "& img": {
        cursor: "pointer",
        width: "210px",
        height: 225,
        paddingRight: 16,
      },
      "&:last-child": {
        "& img": {
          paddingRight: 0,
        },
      },
    },
    blogContainer: {
      border: "1px solid #EEE",
      margin: "16px 12px",
      background: "#FDFDFD",
      borderRadius: "16px",
  },
  },
  carouselBannerDiv: {
    padding:"8px 16px",
    maxWidth: "550px",
    minWidth: "328px",
    height: 150,
    "& img": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },

  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  alignCenter: {
    alignItems: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
}));

export default style;
