import {React} from "react";
import MobileHeader from "./MobileHeader";
import { isMobile } from "react-device-detect";
import DesktopHeader from "./DesktopHeader";

export default function Headers(){


    return(
      <>
      {isMobile?<MobileHeader /> : <DesktopHeader />}
      </>
    )

}