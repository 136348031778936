import { React, useState, useRef } from "react";
import clsx from "clsx";
import SectionWithScrollButtons from "../Common/SectionWithScrollButtons";
import useStyles from "./styles";
import BannerLoader from "../skeletonLoader/BannerLoader";

export default function DeskKmGallery({ cardData, loading }) {
  const classes = useStyles();
  const scrl = useRef(null);

  return (
    <>
    <div> 
      <div className={classes.kmGallery}>
        KheloMore Gallery
      </div>
      {/* <SectionWithScrollButtons
        isBg={true}
        onClickRight={() => {
          scrl.current.scrollLeft += 250;
        }}
        onClickLeft={() => {
          scrl.current.scrollLeft -= 250;
        }}
      > */}
        <div
          className={clsx(classes.flex, classes.scrollContainer)}
          id="content"
          ref={scrl}
        >
      {loading ? <BannerLoader /> :
          cardData?.map((details, idx) => (
            <a key={idx} href={details.redirectionURL} className={classes.homeBanner}>
              <img src={details.imageURL} alt="km gallery" />
              <div className={classes.cardName}>
                {details.cardName}
              </div>
            </a>
          ))
        } 
        </div>
      {/* </SectionWithScrollButtons> */}
    </div>
    </>
  );
}
